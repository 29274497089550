<template>
    <div>{{getAuthToken}} : {{$route.query['token']}}</div>
</template>

<script>
    import { mapMutations, mapGetters } from 'vuex';

    export default {
        computed: {
            ...mapGetters({getAuthToken: 'authToken'}),
        },
        methods: {
            ...mapMutations({setAuthToken: 'authToken'}),
        },
        mounted: function() {
            this.setAuthToken(this.$route.query['token']);
            this.$router.push('/');
        },
    }
</script>