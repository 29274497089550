var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.byRole)?_c('div',[_vm._v(" By Role ")]):(!_vm.useAdvancedRoles)?_c('v-select',{staticClass:"pt-0 mt-0",attrs:{"items":_vm.simpleRoleItems,"value":_vm.displaySimpleRoles,"multiple":"","label":_vm.label || '',"small-chips":"","hide-details":"","chips":"","readonly":_vm.readonly},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","open-delay":200},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":"","color":item.color,"label":""}},'v-chip',attrs,false),on),[_vm._v(_vm._s(item.text))])]}}],null,true)},[_c('div',[_vm._v(_vm._s(item.type))])])]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._b({staticClass:"role-select-item",attrs:{"ripple":"","dense":"","input-value":_vm.selectedSimpleRoles.includes(item.value)},on:{"click":function($event){return _vm.toggleSimpleRole(item)}}},'v-list-item',attrs,false),[_c('v-tooltip',{attrs:{"left":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-content',_vm._g(_vm._b({},'v-list-item-content',attrs,false),on),[_c('v-list-item-title',{style:(item.style)},[_vm._v(" "+_vm._s(item.text)+" "),(item.missingRoles)?_c('span',[_vm._v("*not in "+_vm._s(item.missingRoles.join(', ')))]):_vm._e()])],1)]}}],null,true)},[_c('h6',[_vm._v(_vm._s(item.text))]),_c('div',[_vm._v(_vm._s(item.description))]),(item.missingRoles)?_c('div',[_vm._v("*not in "+_vm._s(item.missingRoles.join(', ')))]):_vm._e()])],1)]}}])}):_c('v-menu',{ref:"roleMenu",attrs:{"disabled":_vm.readonly,"close-on-content-click":false,"transition":"scale-transition","offset-y":"","nudge-width":200},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-select',{staticClass:"pt-0 mt-0",attrs:{"multiple":true,"items":_vm.roleItems,"value":_vm.displayRoles,"label":_vm.label || '',"small-chips":"","hide-details":"","chips":"","readonly":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","open-delay":200},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":"","color":item.color,"label":""}},'v-chip',attrs,false),on),[_vm._v(_vm._s(item.text))])]}}],null,true)},[_c('div',[_vm._v(_vm._s(item.tooltip))])])]}}],null,true)})],1)]}}]),model:{value:(_vm.roleMenu),callback:function ($$v) {_vm.roleMenu=$$v},expression:"roleMenu"}},[_c('v-card',[(_vm.byRole)?_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.simpleSnaRoles),function(simpleRole){return _c('v-list-item',{key:simpleRole.id,staticClass:"role-select-item"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(simpleRole.name)+" ")])],1)],1)}),1):_c('v-card-text',[_c('v-row',_vm._l((_vm.advancedSnaRoles),function(roleGroup){return _c('v-col',{key:roleGroup.title,attrs:{"cols":"4"}},[_c('h5',{staticClass:"text-uppercase"},[_vm._v(_vm._s(roleGroup.title))]),_c('v-list',{staticClass:"role-group-list",attrs:{"dense":""}},_vm._l((roleGroup.items),function(roleItem){return _c('v-list-item',{key:roleItem.id,staticClass:"px-0",attrs:{"color":"primary","link":"","input-value":_vm.effectiveRoles.includes(roleItem.id)},on:{"click":function($event){return _vm.toggleRole(roleItem)}}},[_c('v-tooltip',{attrs:{"left":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-content',_vm._g(_vm._b({},'v-list-item-content',attrs,false),on),[_c('v-list-item-title',[_vm._v(" "+_vm._s(roleItem.id)+" ")])],1)]}}],null,true)},[_c('h6',[_vm._v(_vm._s(roleItem.id))]),_c('span',[_vm._v(_vm._s(roleItem.description))])])],1)}),1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }