<template>
    <v-container class='pa-4'>
        <v-row>
            <v-col>
                <h4 class='py-6'>Send Account Invites</h4>
            </v-col>
        </v-row>
        <v-form ref='form' v-model='valid' @submit.prevent="submit" :lazy-validation="true">
            <v-row>
                <v-col cols='12'>
                    <v-alert
                        border="left"
                        colored-border
                        color=""
                        elevation="2"
                    >
                        Submitting the list of email addresses entered below will send a blank form for each <em><strong>{{accountType.name || 'No account type selected'}}</strong></em> to complete. Once these forms are completed and submitted they will be returned to the to the authorised staff member for verification.
                    </v-alert>
                </v-col>

                <v-col cols='12' v-if='accountType.introBlurb'>
                    <v-alert
                        border="left"
                        colored-border
                        color="primary"
                        elevation="2"
                    >
                        {{accountType.introBlurb}}
                    </v-alert>
                </v-col>

                <v-col cols='6'>
                    <date-input
                        dense
                        v-model="startDate"
                        :rules='[rules.required, rules.auDate]'
                        :options="{label: 'Starting', maxDate: maxStartDate}"
                    >
                    </date-input>
                </v-col>
                <v-col cols='6'>
                    <date-input
                        dense
                        v-model="endDate"
                        :rules='[rules.required, rules.auDate]'
                        :options="{label: 'Ending', minDate: startDate, maxDate: maxEndDate}"
                    >
                    </date-input>
                </v-col>
            </v-row>
            <v-row v-if='hasRoles'>
                <v-col class='mt-3'>
                    <role-selector
                        v-if='advancedSnaRoles'
                        label='Initial Roles'
                        :adds.sync='initialSnaRoles'
                        :removes='[]'
                        :account-type='accountType'
                        :advanced-sna-roles='advancedSnaRoles'
                        :simple-sna-roles='simpleSnaRoles'
                        :initial-roles='[]'
                        :use-advanced-roles='useAdvancedRoles'
                    ></role-selector>
                </v-col>

                <v-col cols='auto'>
                    <v-checkbox
                        dense
                        v-model='useAdvancedRoles'
                        label='Advanced Roles'
                    >
                    </v-checkbox>
                </v-col>
            </v-row>

            <h3 v-if='adminProperties.length' class='mt-10'>Account Defaults</h3>
            <v-row>
                <v-col
                    v-for="property in adminProperties"
                    :key='property.id'
                    :cols='property.options.cols || 12'
                    :xs='property.options.xs'
                    :sm='property.options.sm'
                    :md='property.options.md'
                    :lg='property.options.lg'
                    :xl='property.options.xl'
                >
                    <form-input
                        :type='property.type'
                        v-model='input[property.name]'
                        :options='property.options'
                        :name='property.name'
                    ></form-input>
                </v-col>
            </v-row>
            <v-row class='mt-6'>
                <v-col>
                    <v-textarea
                        v-model="emailAddresses"
                        :rules="ruleEmailAddresses"
                        hint="Place each address on a separate line."
                        :label="'Enter the email address of each ' + accountType.name"
                        required
                        auto-grow
                        outlined
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row class='mt-0'>
                <v-col>
                </v-col>
                <v-col cols='auto' align-self='end'>
                    <v-btn class='mb-8' :class="{primary: !invitesSending}" type='submit' :disabled="!valid || invitesSending" :loading='invitesSending'>Send</v-btn>
                </v-col>
            </v-row>

        </v-form>
        <v-snackbar top right v-model="invitesFailed" color='error'>
            Failed to send invite
            <span v-if="emailAddresses && emailAddresses.trim().split('\n').length > 1">s</span>
        </v-snackbar>
        <v-snackbar top right v-model="invitesSuccess" color='success'>
            Invitation
            <span v-if="emailAddresses && emailAddresses.trim().split('\n').length > 1">s</span> sent
        </v-snackbar>
        <v-snackbar top right v-model='fetchSnaRolesError' color='error'>
            Could not read available roles.
        </v-snackbar>
    </v-container>
</template>

<script>
    import siteVars from '../../site-vars';
    import axios from 'axios';
    import moment from 'moment';
    import FormInput from './form-elements/FormInput.vue';
    import DateInput from './form-elements/DateInput.vue';
    import RoleSelector from './form-elements/RoleSelector';
    import validationRules from "./form-elements/validation-rules";

    export default {
        components: {
            FormInput,
            DateInput,
            RoleSelector,
        },
        props: [
            'accountType',
        ],
        data: () => ({
            valid: true,
            input: {},
            startDate: new Date().toISOString().substr(0, 10),
            endDate: null,
            advancedSnaRoles: null,
            simpleSnaRoles: null,
            initialSnaRoles: [],
            emailAddresses: null,
            ruleEmailAddresses: [all => !all || all.trim().split('\n').every(v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || 'Invalid emails given. Please enter a valid email per line.'],
            invitesSending: false,
            invitesSuccess: false,
            invitesFailed: false,
            validationErrors: {},
            fetchSnaRolesError: false,
            useAdvancedRoles: false,
        }),
        computed: {
            rules: function() {
                return validationRules;
            },
            maxStartDate: function() {
                return moment().add(365, 'days').format('YYYY-MM-DD');
            },
            maxEndDate: function() {
                return moment(this.startDate).add(this.accountType.maxDurationDays - 1, 'days').format('YYYY-MM-DD');
            },
            hasRoles: function() {
                return this.advancedSnaRoles && this.advancedSnaRoles.length;
            },
            adminProperties: function() {
                return this.accountType.properties.filter(p => !p.canOwnerEdit);
            },
        },
        watch: {
            startDate: function(newVal, oldVal) {
                // Move endDate by same offset, if set
                if (this.endDate && oldVal) {
                    var diff = moment(this.endDate).diff(moment(oldVal), 'days');
                    this.endDate = moment(newVal).add(diff, 'days').format('YYYY-MM-DD');
                }
            }
        },
        methods: {
            fetchAvailableSnaRoles: function() {
                const requestUrl = `${siteVars.apiUrl}/account-types/${this.accountType.id}/available-sna-roles`

                axios
                    .get(requestUrl, {
                        params: {
                        }
                    })
                    .then(response => {
                        this.advancedSnaRoles = response.data.advanced;
                        this.simpleSnaRoles = response.data.simple;
                    })
                    .catch((/*error*/) => {
                        this.fetchSnaRolesError = true;
                    });
            },
            saveStartDate (date) {
                this.$refs.startDateMenu.save(date);
            },
            submit: function() {

                this.$refs.form.validate();

                this.$nextTick(() => {

                    if (this.valid) {

                        this.invitesSending = true;

                        const requestUrl = `${siteVars.apiUrl}/account-types/${this.accountType.id}/invites/create-many`
                        axios
                            .post(requestUrl, {
                                startDate: this.startDate,
                                endDate: this.endDate,
                                initialSnaRoles: this.initialSnaRoles,
                                emailAddresses: this.emailAddresses,
                            })
                            .then(/*response*/() => {
                                this.invitesSending = false;
                                this.emailAddresses = '';
                                this.invitesSuccess = true;
                            })
                            .catch(/*response*/() => {
                                this.invitesSending = false;
                                this.invitesFailed = true;
                            });
                    }
                });
            },
            formatDate (date) {
                if (!date) {
                    return null;
                }

                const [year, month, day] = date.split('-');

                return `${day}/${month}/${year}`
            },
        },
        mounted() {
            this.fetchAvailableSnaRoles();
        },
    }
</script>