<template>
    <v-form ref="newAccountTypeForm" v-model="accountTypeValid" @submit.prevent="createAccountType()">
        <v-dialog
            :value='true'
            :persistent='true'
            max-width="860"
        >
            <v-card>
                <v-card-title class="headline">
                    New Account Type
                </v-card-title>

                <v-card-text>
                    <div>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model='name'
                                    label='Account Type Name'
                                    hint='This will appear in the dashboard menu (sorted alphabetically)'
                                    autofocus
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model='snaSite'
                                    label="Syncnova Accounts Site ID"
                                    hint="Site ID as configured in SNA (eg. staff_trinity)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="grey"
                        text
                        @click="$emit('close')"
                    >
                        Cancel
                    </v-btn>

                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        text
                        @click="createAccountType()"
                    >
                        Create
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</template>

<script>
    import siteVars from '../../site-vars';
    import axios from 'axios';

    export default {
        props: {
        },
        data: () => ({
            accountTypeValid: false,
            name: '',
            snaSite: '',
        }),
        computed: {
        },
        watch: {
        },
        methods: {
            createAccountType: function() {

                this.$refs.newAccountTypeForm.validate();

                this.$nextTick(() => {

                    if (this.accountTypeValid) {

                        this.invitesSending = true;

                        const requestUrl = `${siteVars.apiUrl}/account-types/create`
                        axios
                            .post(requestUrl, {
                                name: this.name,
                                snaSite: this.snaSite,
                            })
                            .then(response => {
                                this.$emit('created', response.data.id);
                            })
                            .catch(/*response*/() => {
                            });
                    }
                });
            },
        },
        mounted() {
        },
    }
</script>