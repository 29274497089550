<template>
    <v-form ref="editAccountValidForm" v-model="editAccountValid" @submit.prevent="saveAccount(editingAccount)">
        <v-dialog
            v-if="account && editingAccount"
            v-model="editingAccount"
            :persistent='!readonly'
            max-width="860"
        >
            <v-card :dark='readonly'>
                <v-card-title class="headline">
                    {{accountLabel}}
                </v-card-title>

                <v-card-text>
                    <div>
                        <v-row>
                            <v-col>
                                <v-text-field :readonly='!accountType.access.editAccountEmail' label='Email' v-model='editingAccount.personalEmail'></v-text-field>
                            </v-col>
                            <v-col cols='auto' v-if='editingAccount.archivedAccountMetas.length'>
                                <v-menu
                                    dense
                                    ref='historyMenu'
                                    v-model="historyMenu"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on">
                                            <v-icon>mdi-history</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for='history in editingAccount.archivedAccountMetas'
                                            :key='history.id'
                                            link
                                            @click="fetchArchived(history)"
                                        >
                                            <v-list-item-icon class='mr-2'>
                                                <v-icon>mdi-history</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title class='body-2'>{{historyLabel(history)}}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                            <v-col cols='12' class='ma-0 pa-0'></v-col>

                            <v-col>
                                <date-input
                                    v-model="editingAccount.startDate"
                                    :readonly='readonly'
                                    :options="{label: 'Starting', maxDate: maxStartDate}"
                                >
                                </date-input>
                            </v-col>
                            <v-col>
                                <date-input
                                    v-model="editingAccount.endDate"
                                    :readonly='readonly'
                                    :errors="validationErrors.endDate"
                                    :options="{label: 'Ending', minDate: startDate, maxDate: maxEndDate}"
                                >
                                </date-input>
                            </v-col>
                            <v-col v-if='editingAccount.archivedAt'>
                                <date-input
                                    v-model="editingAccount.archivedAt"
                                    :readonly='readonly'
                                    :options="{label: 'Archived'}"
                                >
                                </date-input>
                            </v-col>
                            <v-col cols='12' class='ma-0 pa-0'></v-col>
                            <v-col v-for="property in accountType.properties" :key='property.id' :cols='property.options.cols || 12'>
                                <form-input
                                    :type='property.type'
                                    v-model='editingAccount.state[property.name]'
                                    :readonly='readonly'
                                    :options='property.options'
                                    :name='property.name'
                                ></form-input>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-btn
                        color="darkgrey"
                        text
                        @click="editingAccount = false"
                    >
                        <span v-if='readonly'>Close</span>
                        <span v-else>Cancel</span>
                    </v-btn>

                    <v-spacer></v-spacer>

                    <v-btn
                        v-if='!readonly'
                        color="primary"
                        text
                        @click="saveAccount(editingAccount)"
                    >
                        Save Account
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</template>

<script>
    import siteVars from '../../site-vars';
    import axios from 'axios';
    import moment from 'moment';
    import FormInput from './form-elements/FormInput.vue';
    import _ from 'lodash';
    import DateInput from './form-elements/DateInput.vue';

    export default {
        components: {
            FormInput,
            DateInput,
        },
        props: {
            account: Object,
            accountType: Object,
            readonly: Boolean,
        },
        data: () => ({
            editingAccount: null,
            editAccountValid: false,
            validationErrors: {},
            historyMenu: false,
        }),
        computed: {
            accountLabel: function() {
                if (this.editingAccount && this.editingAccount.archivedAt) {
                    return 'Account History - '
                        + moment(this.editingAccount.createdAt).format('DD/MM/YYYY') + ' - '
                        + moment(this.editingAccount.archivedAt).format('DD/MM/YYYY');
                } else {
                    return 'Editing Account';
                }
            },
            startDate: function() {
                return this.editingAccount ? this.editingAccount.startDate : null;
            },
            endDate: function() {
                return this.editingAccount ? this.editingAccount.endDate : null;
            },
            maxStartDate: function() {
                return moment().add(365, 'days').format('YYYY-MM-DD');
            },
            maxEndDate: function() {
                return moment(this.startDate).add(this.accountType.maxDurationDays - 1, 'days').format('YYYY-MM-DD');
            },
        },
        watch: {
            account: function(newVal) {
                this.editingAccount = _.cloneDeep(newVal);
            },
            editingAccount: function(newVal) {
                if (!newVal) {
                    this.$emit('close');
                }
            },
            startDate: function(newVal, oldVal) {
                // Move endDate by same offset, if set
                if (this.endDate && oldVal) {
                    var diff = moment(this.endDate).diff(moment(oldVal), 'days');
                    this.editingAccount.endDate = moment(newVal).add(diff, 'days').format('YYYY-MM-DD');
                }
            }
        },
        methods: {
            headingLabel: function(name) {
                return _.startCase(name);
            },
            historyLabel: function(history) {
                return moment(history.startedAt).format('DD/MM/YYYY') + ' - ' + moment(history.terminatedAt).format('DD/MM/YYYY');
            },
            editAccount: function(account) {
                this.editingAccount = account;
            },
            saveAccount: function(editingAccount) {
                const requestUrl = `${siteVars.apiUrl}/accounts/${editingAccount.id}`

                axios
                    .put(requestUrl, {
                        state: editingAccount.state,
                        startDate: editingAccount.startDate,
                        endDate: editingAccount.endDate,
                        personalEmail: editingAccount.personalEmail,
                    })
                    .then(() => {
                        this.$emit('saved');
                    })
                    .catch((error) => {
                        if (error.response.status == '422') {
                            this.validationErrors = error.response.data.errors;
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                        }
                    });
            },
            formatDate (date) {
                if (!date) {
                    return null;
                }

                const [year, month, day] = date.split('-');

                return `${day}/${month}/${year}`
            },
            fetchArchived: function(account) {
                const requestUrl = `${siteVars.apiUrl}/accounts/${account.id}`

                axios
                    .get(requestUrl, {
                    })
                    .then((response) => {
                        this.$emit('showArchived', response.data.data);
                    })
                    .catch(() => {
                    });
            },
        },
        mounted() {
            this.editingAccount = _.cloneDeep(this.account);
        },
    }
</script>

<style>

</style>