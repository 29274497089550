import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import InviteResponse from '../views/InviteResponse.vue'
import InviteResponseSubmitted from '../views/InviteResponseSubmitted.vue'
import ProviderCatchToken from '../views/ProviderCatchToken.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'accounts',
        component: Dashboard
    },
    {
        path: '/provider',
        name: 'catch-token',
        component: ProviderCatchToken
    },
    {
        path: '/invites',
        name: 'invites',
        component: Dashboard
    },
    {
        path: '/invites-pending',
        name: 'invites-pending',
        component: Dashboard
    },
    {
        path: '/invite-form/:id',
        name: 'invite-form',
        component: InviteResponse
    },
    {
        path: '/submitted',
        name: 'invite-form-submitted',
        component: InviteResponseSubmitted,
        props: true,
    },
    {
        path: '/import',
        name: 'import',
        component: Dashboard
    },
    {
        path: '/add',
        name: 'add',
        component: Dashboard
    },
    {
        path: '/settings',
        name: 'settings',
        component: Dashboard
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
