<template>
    <date-input
        dense
        v-if="type == 'string' && options.format == 'date'"
        :options="options"
        :rules="rules"
        :name="name"
        :type="type"
        :value="value"
        :errors="errors"
        :readonly="readonly"
        @input="$emit('input', $event)"
    >
    </date-input>
    <v-select
        dense
        v-else-if="type == 'string' && Array.isArray(options.enum)"
        :value='value'
        :items='selectOptions'
        :label='label'
        :error-messages="validationErrors.map(e => e.message)"
        :rules="rules"
        :readonly="readonly"
        @input="$emit('input', $event)"
    ></v-select>
    <v-text-field
        dense
        v-else-if="type == 'string'"
        :value='value'
        :label='label'
        :error-messages="validationErrors.map(e => e.message)"
        :rules="rules"
        :readonly="readonly"
        @input="$emit('input', $event)"
    ></v-text-field>
    <v-text-field
        dense
        v-else-if="type == 'number'"
        :error-messages="validationErrors.map(e => e.message)"
        type='number'
        :label='label'
        :value='value'
        :rules="rules"
        :readonly="readonly"
        @input="$emit('input', $event)"
    ></v-text-field>
    <v-row
        v-else-if="type == 'null'"
    >
        <v-col>
            <v-alert
                border="left"
                colored-border
                color="primary"
                elevation="2"
            >
                {{options.message}}
            </v-alert>
        </v-col>
    </v-row>
    <v-select
        dense
        v-else-if="type == 'boolean'"
        :value='value'
        :items="options.labels ? selectOptions : [{value: false, text: 'yes'}, {value: true, text: 'no'}, {value: null, text: '-'}]"
        :label='label'
        :error-messages="validationErrors.map(e => e.message)"
        :rules="rules"
        :readonly="readonly"
        @input="$emit('input', $event)"
    ></v-select>
    <div v-else>{{type}}</div>
</template>

<script>
    import _ from 'lodash';
    import DateInput from './DateInput.vue';
    import validationRules from './validation-rules';

    export default {
        components: {
            DateInput,
        },
        props: {
            options: Object,
            readonly: Boolean,
            name: String,
            type: String,
            value: [String, Number, Array, Object, Boolean, null],
            errors: [Array, null],
        },
        data: function() {
            return {
                dateMenu: false,
            };
        },
        computed: {
            selectOptions: function() {
                if (this.options.labels) {
                    return Object.keys(this.options.labels).map(function(key) {
                        return {
                            text: this.options.labels[key],
                            value: key,
                        }
                    });
                } else {
                    return this.options.enum.map(function(value) {
                        return {
                            text: value,
                            value: value,
                        };
                    });
                }
            },
            validationErrors: function() {
                return this.errors || [];
            },
            rules: function() {
                if (!this.options.rules) {
                    return [];
                }

                var rules = [];
                Object.entries(this.options.rules).forEach(([ruleString, message]) => {
                    var [rule,param] = ruleString.split(':');

                    if (validationRules[rule]) {
                        rules.push(value => validationRules[rule](value) || message);
                    } else {
                        if (rule == 'regex') {
                            console.log({param});
                            rules.push(value => !value || new RegExp(param).test(value) || message);
                        }
                    }
                });

                return rules;
            },
            label: function() {
                if (this.options && this.options.label !== undefined) {
                    return this.options.label;
                }

                return _.startCase(this.name);
            },
            dateFormatted: function() {
                return this.formatDate(this.value);
            },
        },
        watch: {
            dateMenu (val) {
                val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
            },
        },
        methods: {
            saveDate (date) {
                this.$refs.dateMenu.save(date);
                this.$emit('input', date);
            },
        },
        mounted() {
        },
    }
</script>