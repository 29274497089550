<template>
    <v-app id="gathering-admin">
        <v-container class='pa-4'>
            <v-form v-if='invite'>
                <v-row>
                    <v-col>
                        <v-alert
                            border="left"
                            colored-border
                            color=""
                            elevation="2"
                        >
                            Please fill out the form below to create a new account.
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field readonly label='Email' :value='invite.personalEmail'></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if='invite.canOwnerSetSchedule'>
                    <v-col cols='6'>
                        <date-input
                            dense
                            v-model="startDate"
                            :rules='[rules.required, rules.auDate]'
                            :options="{label: 'Starting', maxDate: maxStartDate}"
                        >
                        </date-input>
                    </v-col>
                    <v-col cols='6'>
                        <date-input
                            dense
                            v-model="endDate"
                            :rules='[rules.required, rules.auDate]'
                            :options="{label: 'Ending', minDate: startDate, maxDate: maxEndDate}"
                        >
                        </date-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        v-for="property in invite.properties"
                        :key='property.id'
                        :cols='property.options.cols || 12'
                        :xs='property.options.xs'
                        :sm='property.options.sm'
                        :md='property.options.md'
                        :lg='property.options.lg'
                        :xl='property.options.xl'
                    >
                        <form-input :type='property.type' v-model='input[property.name]' :options='property.options' :name='property.name'></form-input>
                    </v-col>
                </v-row>
                <v-row>
                        <v-col>
                        <v-btn color='primary' @click='saveFormData'>Request Account</v-btn>
                    </v-col>
                    <v-col cols='auto'>
                    </v-col>
                </v-row>
            </v-form>
            <v-row v-if='fetchError'>
                <v-col>
                    <v-alert
                        border="left"
                        colored-border
                        color='warning'
                        elevation='2'
                    >
                        This account invite has expired or has been canceled. Please request a new invite.
                    </v-alert>
                </v-col>
            </v-row>
        </v-container>
        <v-snackbar top right v-model='submitError' color='error'>Sorry, this service is currently down. Please try again later.</v-snackbar>
    </v-app>
</template>

<script>
    import siteVars from '../../site-vars';
    import axios from 'axios';
    import FormInput from './form-elements/FormInput.vue';
    import DateInput from './form-elements/DateInput.vue';
    import validationRules from "./form-elements/validation-rules";
    import moment from "moment";

    export default {
        components: {
            FormInput,
            DateInput,
        },
        props: {
        },
        data: function() {
            return {
                invite: null,
                input: {},
                submitError: false,
                fetchError: false,
                startDate: null,
                endDate: null,
            };
        },
        computed: {
            rules: function() {
                return validationRules;
            },
            maxStartDate: function() {
                return moment().add(365, 'days').format('YYYY-MM-DD');
            },
            maxEndDate: function() {
                return moment(this.startDate).add(this.invite.maxDurationDays - 1, 'days').format('YYYY-MM-DD');
            },
        },
        watch: {
            startDate (newVal, oldVal) {
                // Move endDate by same offset, if set
                if (this.endDate && oldVal) {
                    var diff = moment(this.endDate).diff(moment(oldVal), 'days');
                    this.endDate = moment(newVal).add(diff, 'days').format('YYYY-MM-DD');
                }
            },
        },
        methods: {
            fetchFormData () {
                const requestUrl = `${siteVars.apiUrl}/invites/${this.$route.params.id}/form`

                axios
                    .get(requestUrl, {
                        params: {
                            expires: this.$route.query.expires,
                            signature: this.$route.query.signature,
                        }
                    })
                    .then(response => {
                        this.invite = response.data.data;
                        this.startDate = this.invite.startDate.substr(0, 10);
                        this.endDate = this.invite.endDate.substr(0, 10);
                        this.input = this.invite.baseState || {};
                    })
                    .catch(error => {
                        console.log(error.response);
                        this.fetchError = true;
                    });
            },
            saveFormData () {
                const requestUrl = `${siteVars.apiUrl}/invites/${this.$route.params.id}/submit`

                axios
                    .post(requestUrl, {
                        expires: this.$route.query.expires,
                        signature: this.$route.query.signature,
                        state: this.input,
                    })
                    .then(() => {
                        this.$router.push({
                            name: 'invite-form-submitted',
                            params: {invite: this.invite, startDate: this.startDate, endDate: this.endDate},
                        });
                    })
                    .catch(() => {
                        this.submitError = true;
                    });
            },
            parseDate (date) {
                if (!date) return null

                const [day, month, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
        },
        mounted() {
            this.fetchFormData();
        },
    }
</script>