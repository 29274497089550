<template>
    <v-container>
        <v-row>
            <v-col>
                <h4 class='py-6'>Approve Invitees</h4>
            </v-col>
        </v-row>
        <v-form v-model="valid">
            <v-row>
                <v-col>
                    <v-text-field
                        v-model="searchString"
                        label="Search..."
                        clearable
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-select
                        v-model="submittedState"
                        :items="submittedStateItems"
                        label="Status"
                    ></v-select>
                </v-col>
                <v-col>
                    <v-select
                        v-model="pageSize"
                        :items="pageSizeItems"
                        label="Showing"
                    ></v-select>
                </v-col>
                <v-col>
                    <v-checkbox
                        v-model="useAdvancedRoles"
                        label="Advanced Roles"
                    ></v-checkbox>
                </v-col>
                <v-col>
                </v-col>
                <v-col cols='auto'>
                    <v-btn
                        @click='resetTrigger += 1'
                    >Reset</v-btn>
                </v-col>
                <v-col cols='auto'>
                    <v-btn
                        @click='saveTrigger += 1'
                        color="primary"
                    >Save</v-btn>
                </v-col>
            </v-row>
        </v-form>

        <v-row class='my-2'>
            <v-col cols='auto' :class="{'grey--text': !hasAnySelected}">
                <span class='d-inline-block pt-1'>With Selected</span>
            </v-col>
            <v-col cols='auto'>
                <v-btn @click='approveMany' color='primary' :disabled='!hasSubmittedInvitesSelected'>Approve</v-btn>
            </v-col>
            <v-col>
            </v-col>
            <v-col cols='auto'>
                <v-btn @click='revokeMany' color='warning' :disabled='!hasWaitingInvitesSelected'>Revoke Invites</v-btn>
            </v-col>
        </v-row>
        <v-card class='pa-4 soft-shadow'>
            <v-simple-table class='invites-table' dense>
                <thead>
                    <tr>
                        <th style='width: 1%'>
                            <v-simple-checkbox v-model='selectAllToggle'></v-simple-checkbox>
                        </th>
                        <th style='width: 1%'>ID</th>
                        <th style='width: 1%'>Expires</th>
                        <th style='width: 1%'>Email</th>
                        <th style='width: 1%' v-for="property in inputProperties" :key="property.id" v-html="headingLabel(property.name)"></th>
                        <th style='width: 1%'>Status</th>
                        <th>Initial Roles</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(invite,index) in invites">
                        <invite-list-item
                            v-model='invite.selected'
                            :properties='inputProperties'
                            :invite='invite'
                            :use-advanced-roles='useAdvancedRoles'
                            :account-type='accountType'
                            :advanced-sna-roles='advancedSnaRoles'
                            :key="index"
                            :save-trigger='saveTrigger'
                            :reset-trigger='resetTrigger'
                            @rolesUpdated="rolesUpdated = true"
                            @rolesUpdateError="rolesUpdateError = true"
                        ></invite-list-item>
                    </template>
                </tbody>
            </v-simple-table>
        </v-card>

        <v-snackbar
            outlined
            top
            right
            v-model='rolesUpdateError'
            color='error'
        >
            Roles could not save. Please refresh the page and try again.
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="success"
                    text
                    icon
                    v-bind="attrs"
                    @click="rolesUpdateError = false"
                >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>


        </v-snackbar>
        <v-snackbar
            outlined
            top
            right
            v-model='rolesUpdated'
            color='success'
        >
            Roles saved
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="success"
                    text
                    icon
                    v-bind="attrs"
                    @click="rolesUpdated = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

    </v-container>
</template>

<script>
    import InviteListItem from './InviteListItem.vue';
    import _ from 'lodash';
    import siteVars from '../../site-vars';
    import axios from 'axios';

    export default {
        components: {
            InviteListItem,
        },
        props: {
            accountType: Object,
        },
        data: () => ({
            valid: true,
            searchString: '',
            advancedSnaRoles: null,
            simpleSnaRoles: null,
            submittedState: 'all',
            pageSize: 20,
            invites: null,
            invitesMeta: null,
            selectAllToggle: null,
            useAdvancedRoles: false,
            saveTrigger: 0,
            resetTrigger: 0,
            rolesUpdateError: false,
            rolesUpdated: false,
        }),
        computed: {
            inputProperties: function() {
                return this.accountType.properties.filter(p => p.type !== 'null');
            },
            hasAnySelected: function() {
                return this.invites && this.invites.some(i => i.selected);
            },
            hasWaitingInvitesSelected: function() {
                return this.invites && this.invites.filter(i => i.selected).some(i => !i.submittedAt);
            },
            hasSubmittedInvitesSelected: function() {
                return this.invites && this.invites.filter(i => i.selected).some(i => !!i.submittedAt && !i.provisionedAccount.approvedAt);
            },
            submittedStateItems: function() {
                return [
                    {
                        value: 'all',
                        text: 'All',
                    },
                    {
                        value: 'waiting',
                        text: 'Waiting Response',
                    },
                    {
                        value: 'submitted',
                        text: 'Submitted',
                    },
                    {
                        value: 'approved',
                        text: 'Approved',
                    },
                    {
                        value: 'revoked',
                        text: 'Revoked',
                    },
                ];
            },
            pageSizeItems: function() {
                return [
                    {
                        value: 10,
                        text: '10 items',
                    },
                    {
                        value: 20,
                        text: '20 items',
                    },
                    {
                        value: 50,
                        text: '50 items',
                    },
                    {
                        value: 100,
                        text: '100 items',
                    },
                    {
                        value: 200,
                        text: '200 items',
                    },
                ];
            },
        },
        watch: {
            accountType: function(newVal) {
                if (newVal) {
                    this.fetch();
                }
            },
            searchString: function() {
                this.fetch();
            },
            pageSize: function() {
                this.fetch();
            },
            submittedState: function() {
                this.fetch();
            },
            selectAllToggle: function(val) {
                this.selectAll(val);
            },
            rolesUpdated: function() {
                this.fetch();
            },
        },
        methods: {
            fetch: function() {
                const requestUrl = `${siteVars.apiUrl}/account-types/${this.accountType.id}/invites`

                axios
                    .get(requestUrl, {
                        params: {
                            searchString: this.searchString,
                            pageSize: this.pageSize,
                            submittedState: this.submittedState,
                        }
                    })
                    .then(response => {
                        this.invites = response.data.data.map(i => {
                            i.selected = false;

                            return i;
                        });
                        this.invitesMeta = response.data.meta;
                    });
            },
            fetchAvailableSnaRoles: function() {
                const requestUrl = `${siteVars.apiUrl}/account-types/${this.accountType.id}/available-sna-roles`

                axios
                    .get(requestUrl, {
                        params: {
                        }
                    })
                    .then(response => {
                        this.advancedSnaRoles = response.data.advanced;
                        this.simpleSnaRoles = response.data.simple;
                    });
            },
            headingLabel: function(name) {
                return _.startCase(name);
            },
            selectAll: function(value) {
                this.invites.map(i => {
                    i.selected = value;
                })
            },
            approveMany: function() {

                this.invitesSending = true;

                const requestUrl = `${siteVars.apiUrl}/account-types/${this.accountType.id}/invites/approve-many`
                axios
                    .post(requestUrl, {
                        inviteIds: this.invites.filter(i => i.selected && !!i.submittedAt).map(i => i.id),
                    })
                    .then(/*response*/() => {
                        this.fetch();
                    })
                    .catch(/*response*/() => {
                        this.invitesFailed = true;
                    });
            },
            revokeMany: function() {

                this.invitesSending = true;

                const requestUrl = `${siteVars.apiUrl}/account-types/${this.accountType.id}/invites/revoke-many`
                axios
                    .post(requestUrl, {
                        inviteIds: this.invites.filter(i => i.selected && !i.submittedAt).map(i => i.id),
                    })
                    .then(/*response*/() => {
                        this.fetch();
                    })
                    .catch(/*response*/() => {
                        this.invitesFailed = true;
                    });
            },
        },
        mounted() {
            this.fetch();
            this.fetchAvailableSnaRoles();
        },
    }
</script>

<style>
    .invites-table tr th {
        font-size: 0.875rem !important;
        font-weight: normal !important;
        color: #999 !important;
        text-transform: uppercase !important;
        line-height: 3 !important;
        white-space: nowrap;
    }

    .invites-table tr td {
        color: #666 !important;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.5;
    }

    .invites-table tr {
        height: 45px;
    }

    .invites-table tbody > tr:hover {
        background: #ffd !important;
    }

    .theme--light.v-data-table tbody tr:not(:last-child) > td,
    .theme--light.v-data-table thead tr > th {
        border-bottom: none !important;
    }

    .soft-shadow {
        color: #666;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08) !important;
    }

</style>