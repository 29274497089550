<template>
    <v-container class='pa-4'>
        <v-row>
            <v-col>
                <h4 class='py-6'>Bulk Account Imports</h4>
            </v-col>
        </v-row>
        <v-row v-if='uploadSuccess'>
            <v-col>
                <v-alert elevation="2" border="left" colored-border v-model='uploadSuccess' color='success'>
                    Import success
                </v-alert>
                <v-btn class='mt-10' @click='uploadSuccess = false'>Perform another Import</v-btn>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col v-if='accountType.introBlurb'>
                <v-alert
                    border="left"
                    colored-border
                    color="primary"
                    elevation="2"
                >
                    {{accountType.introBlurb}}
                </v-alert>
            </v-col>

            <v-col cols='12'>
                <v-alert
                    border="left"
                    colored-border
                    color=""
                    elevation="2"
                >
                    Step 1. Download the CSV and fill in all columns provided. Ensure that you replace the text in the example row.
                </v-alert>
            </v-col>

            <v-col cols='12'>
                <v-btn @click='downloadTemplate'>
                    <v-icon left>
                        mdi-download
                    </v-icon>
                    Download CSV Template
                </v-btn>
            </v-col>

            <v-col class='mt-7' cols='12'>
                <v-alert
                    border="left"
                    colored-border
                    color=""
                    elevation="2"
                >
                    Step 2. Upload the completed CSV file
                </v-alert>
            </v-col>

            <v-col cols='12'>
                <v-btn
                    color="primary"
                    :loading="isSelectingUpload"
                    @click="onUploadClick"
                >
                    <v-icon left>
                        mdi-upload
                    </v-icon>
                    Upload Completed Template
                </v-btn>
                <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    accept="text/csv"
                    @change="uploadTemplate"
                >
            </v-col>
        </v-row>
        <template v-if='validationErrors'>
            <v-row>
                <v-col>
                    <v-alert
                        color="error"
                        colored-border
                        elevation="2"
                    >
                        The file failed to upload:
                    </v-alert>
                </v-col>
            </v-row>
            <v-row v-for='(validationError,index) in validationErrors' :key='index'>
                <v-col>
                    <v-alert
                        border="left"
                        colored-border
                        color="error"
                        elevation="2"
                    >
                        {{validationError}}
                    </v-alert>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
    import { saveAs } from 'file-saver';
    import siteVars from '../../site-vars';
    import axios from 'axios';

    export default {
        props: {
            accountType: Object,
        },
        data: () => ({
            isSelectingUpload: false,
            uploadSuccess: false,
            validationErrors: null,
        }),
        computed: {
        },
        watch: {
        },
        methods: {
            downloadTemplate: function() {

                const requestUrl = `${siteVars.apiUrl}/account-types/${this.accountType.id}/download-template`

                axios
                    .get(requestUrl, {
                        params: {
                        },
                        responseType: 'blob',
                    })
                    .then(response => {
                        var contentDisposition = response.headers["content-disposition"] || "";

                        var filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                        var filename = filenameMatch ? filenameMatch[1].replace(/['"]/g, '') : 'report';

                        saveAs(response.data, filename);

                        return;
                    })
                    .catch((error) => {
                        console.error(error)
                    });
            },
            uploadTemplate: function(e) {

                var formData = new FormData();
                formData.append('import', e.target.files[0]);

                const requestUrl = `${siteVars.apiUrl}/account-types/${this.accountType.id}/import-template`

                axios
                    .post(requestUrl,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            }
                        },
                    )
                    .then(() => {
                        this.validationErrors = null;
                        this.uploadSuccess = true;
                    })
                    .catch((error) => {
                        console.error(error);
                        this.validationErrors = error.response.data ? error.response.data.errors : ['Error uploading file.'];
                    });
            },
            onUploadClick: function() {
                this.isSelecting = true;
                window.addEventListener('focus', () => {
                    this.isSelecting = false;
                }, {
                    once: true
                });

                this.$refs.uploader.click()
            },
        },
        mounted() {
        },
    }
</script>