<template>
    <div v-if='byRole'>
        By Role
    </div>

    <v-select
        v-else-if='!useAdvancedRoles'
        :items='simpleRoleItems'
        :value='displaySimpleRoles'
        multiple
        :label="label || ''"
        small-chips
        hide-details
        class='pt-0 mt-0'
        chips
        :readonly='readonly'
    >
        <template #selection="{ item }">
            <v-tooltip top :open-delay='200'>
                <template v-slot:activator="{ on, attrs }">
                    <v-chip
                        v-bind="attrs"
                        v-on="on"
                        small
                        :color="item.color"
                        label
                    >{{item.text}}</v-chip>
                </template>
                <div>{{item.type}}</div>
            </v-tooltip>
        </template>
        <template #item="{ item, on, attrs }">
            <v-list-item
                ripple
                dense
                @click='toggleSimpleRole(item)'
                :input-value='selectedSimpleRoles.includes(item.value)'
                v-bind="attrs"
                class='role-select-item'
            >

                <v-tooltip left :open-delay='500'>
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item-content v-bind="attrs" v-on="on">
                            <v-list-item-title :style='item.style'>
                                {{item.text}}
                                <span v-if='item.missingRoles'>*not in {{item.missingRoles.join(', ')}}</span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <h6>{{item.text}}</h6>
                    <div>{{item.description}}</div>
                    <div v-if='item.missingRoles'>*not in {{item.missingRoles.join(', ')}}</div>
                </v-tooltip>
            </v-list-item>
        </template>
    </v-select>

    <v-menu
        v-else
        ref='roleMenu'
        v-model="roleMenu"
        :disabled='readonly'
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        :nudge-width="200"
    >
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
                <v-select
                    :multiple="true"
                    :items="roleItems"
                    :value="displayRoles"
                    :label="label || ''"
                    small-chips
                    hide-details
                    class='pt-0 mt-0'
                    chips
                    readonly
                >
                    <template #selection="{ item }">
                        <v-tooltip top :open-delay='200'>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    :color="item.color"
                                    label
                                >{{item.text}}</v-chip>
                            </template>
                            <div>{{item.tooltip}}</div>
                        </v-tooltip>
                    </template>
                </v-select>
            </div>
        </template>
        <v-card>
            <v-list dense v-if='byRole'>
                <v-list-item class='role-select-item' v-for='simpleRole in simpleSnaRoles' :key='simpleRole.id'>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{simpleRole.name}}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-card-text v-else>
                <v-row>
                    <v-col cols='4' v-for='roleGroup in advancedSnaRoles' :key='roleGroup.title' >
                        <h5 class='text-uppercase'>{{roleGroup.title}}</h5>
                        <v-list class='role-group-list' dense>
                            <v-list-item
                                v-for='roleItem in roleGroup.items'
                                @click='toggleRole(roleItem)'
                                color='primary'
                                link
                                class='px-0'
                                :input-value='effectiveRoles.includes(roleItem.id)'
                                :key='roleItem.id'
                            >
                                <v-tooltip left :open-delay='500'>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-list-item-content v-bind="attrs" v-on="on">
                                            <v-list-item-title>
                                                {{roleItem.id}}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                    <h6>{{roleItem.id}}</h6>
                                    <span>{{roleItem.description}}</span>
                                </v-tooltip>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        props: {
            readonly: Boolean,
            accountType: Object,
            advancedSnaRoles: Array,
            simpleSnaRoles: Array,
            initialRoles: Array,
            adds: Array,
            removes: Array,
            useAdvancedRoles: Boolean,
            byRole: String,
            saveTrigger: Number,
            resetTrigger: Number,
            label: String,
        },
        data: function() {
            return {
                roleMenu: false,
            };
        },
        computed: {
            roleGroups: function() {
                return [];
            },
            effectiveRoles: function() {
                return this.initialRoles.concat(this.adds).filter(r => !this.removes.includes(r));
            },
            displayRoles: function() {
                return this.initialRoles.concat(this.adds);
            },
            roleItems: function() {
                return this.advancedSnaRoles.reduce((acc, group) => acc.concat(group.items.map(r => r.id)), []).map(snaRole => {

                    var added = this.adds.includes(snaRole);
                    var removed = this.removes.includes(snaRole);

                    var tooltip = added ? 'Adding role' : (removed ? 'Removing role' : 'no change');
                    var color = added ? 'success' : (removed ? 'error' : 'primary');

                    return {
                        text: snaRole,
                        value: snaRole,
                        tooltip: tooltip,
                        color: color,
                    }
                });
            },
            simpleRoleItems: function() {
                return this.simpleSnaRoles.map(role => {

                    var missing = role.snaRoles.filter(r => !this.effectiveRoles.includes(r));
                    if (missing.length == 0 || missing.length == role.snaRoles.length) {
                        missing = undefined;
                    }

                    var added = role.snaRoles.some(r => this.adds.includes(r));
                    var removed = role.snaRoles.some(r => this.removes.includes(r));

                    var type = missing ? `Partial role (missing: ${missing.join(', ')})` : (added ? 'Adding role' : (removed ? 'Removing role' : 'no change'));
                    var color = missing ? 'default' : (added ? 'success' : (removed ? 'error' : 'primary'));

                    return {
                        text: role.name,
                        value: role.name,
                        snaRoles: role.snaRoles,
                        description: role.description,
                        missingRoles: missing,
                        type: type,
                        color: color,
                        style: role.style,
                    };
                });
            },
            displaySimpleRoles: function() {
                return this.simpleSnaRoles.map(role => {
                    // Does the use have all sna roles from simple role
                    if (role.snaRoles.some(r => this.displayRoles.includes(r))) {
                        return role.name;
                    }

                    return null;
                }).filter(selected => !!selected);
            },
            selectedSimpleRoles: function() {
                return this.simpleSnaRoles.map(role => {
                    // Does the use have all sna roles from simple role
                    if (role.snaRoles.some(r => this.effectiveRoles.includes(r))) {
                        return role.name;
                    }

                    return null;
                }).filter(selected => !!selected);
            },
        },
        watch: {
        },
        methods: {
            simpleRolesMissing: function(snaRoles, allSelected) {
                var missing = snaRoles.filter(r => !allSelected.includes(r));
                // Missing some, but not all
                if (missing > 0 && missing.length < snaRoles.length) {
                    return missing;
                } else {
                    return null;
                }
            },
            removeSimpleRole: function(item) {
                const selected = item.snaRoles.filter(r => this.effectiveRoles.includes(r));
                this.$emit('update:removes', this.removes.concat(selected));
            },
            addSimpleRole: function(item) {
                const notSelected = item.snaRoles.filter(r => !this.effectiveRoles.includes(r));
                this.$emit('update:adds', this.adds.concat(notSelected));
            },
            resetSimpleRole: function(item) {
                this.$emit('update:adds', this.adds.filter(r => !item.snaRoles.includes(r)));
                this.$emit('update:removes', this.removes.filter(r => !item.snaRoles.includes(r)));
            },
            toggleSimpleRole: function(item) {

                var initiallySelected = item.snaRoles.some(r => this.initialRoles.includes(r));
                var initiallyPartial = initiallySelected && item.snaRoles.some(r => !this.initialRoles.includes(r));

                var allSelected = item.snaRoles.every(r => this.effectiveRoles.includes(r));
                var noneSelected = !item.snaRoles.some(r => this.effectiveRoles.includes(r));

                // Three way toggle when initially a partial selection
                if (initiallyPartial) {
                    if (allSelected) {
                        this.resetSimpleRole(item);
                    } else if (noneSelected) {
                        this.resetSimpleRole(item);
                        this.$nextTick(() => {
                            this.addSimpleRole(item);
                        });
                    } else { // Some selected
                        this.resetSimpleRole(item);
                        this.$nextTick(() => {
                            this.removeSimpleRole(item);
                        });
                    }
                } else if (initiallySelected) {
                    if (noneSelected) {
                        this.resetSimpleRole(item);
                    } else {
                        this.removeSimpleRole(item);
                    }
                } else {
                    if (noneSelected) {
                        this.addSimpleRole(item);
                    } else {
                        this.resetSimpleRole(item);
                    }
                }
            },
            toggleRole: function(item) {

                var initiallySelected = this.initialRoles.includes(item.id);
                var currentlySelected = this.effectiveRoles.includes(item.id);

                if (initiallySelected) {
                    if (currentlySelected) {
                        this.$emit('update:removes', this.removes.concat([item.id]));
                    } else {
                        this.$emit('update:removes', this.removes.filter(r => r != item.id));
                    }

                } else {
                    if (currentlySelected) {
                        this.$emit('update:adds', this.adds.filter(r => r != item.id));
                    } else {
                        this.$emit('update:adds', this.adds.concat([item.id]));
                    }
                }
            },
        },
        mounted() {
        },
    }
</script>

<style>
    .role-group-list {
        max-height: 200px;
        overflow-y: auto;
    }

    .role-select-item {
        font-size: 0.8em;
    }
</style>