<template>
    <tr>
        <td>
            <v-simple-checkbox :value='value' @input="$emit('input', $event)"></v-simple-checkbox>
        </td>
        <td>
            <span class='grey--text darken-2 text-no-wrap'>{{invite.id}}</span>
        </td>
        <td>
            <span class='darken-2 text-no-wrap' :class="{'red--text': expired}">{{expires.format('YYYY-MM-DD h:mm a')}}</span>
        </td>
        <td>
            <span class='grey--text darken-2'>{{invite.personalEmail}}</span>
        </td>
        <td
            v-for="property in properties"
            :key="property.id"
            v-html="invite.provisionedAccount ? invite.provisionedAccount.state[property.name] : (invite.baseState ? invite.baseState[property.name] : '-')"
        ></td>
        <td>
            <span v-if='invite.deletedAt' class='error--text'>revoked</span>
            <span v-else-if='invite.provisionedAccount && invite.provisionedAccount.approvedAt' class='success--text'>approved</span>
            <span v-else-if='invite.submittedAt' class='primary--text'>received</span>
            <span v-else class='warning--text text-no-wrap'>waiting response</span>
        </td>

        <td>
            <role-selector
                :adds.sync='roleAdds'
                :removes.sync='roleRemoves'
                :account-type='accountType'
                :advanced-sna-roles='advancedSnaRoles'
                :simple-sna-roles='simpleSnaRoles'
                :initial-roles='invite.initialSnaRoles'
                :use-advanced-roles='useAdvancedRoles'
                :readonly='!!approved'
                :by-role='undefined'
            ></role-selector>
        </td>
    </tr>
</template>

<script>
    import RoleSelector from './form-elements/RoleSelector';
    import siteVars from '../../site-vars';
    import axios from 'axios';
    import moment from "moment";

    export default {
        components: {
            RoleSelector,
        },
        props: [
            'value',
            'accountType',
            'invite',
            'properties',
            'advancedSnaRoles',
            'useAdvancedRoles',
            'resetTrigger',
            'saveTrigger',
        ],
        data: function() {
            return {
                roleAdds: [],
                roleRemoves: [],
                valid: true,
                name: '',
                roles: [],
                activeOnly: '',
                pageSize: 20,
            };
        },
        computed: {
            approved: function() {
                return this.invite.provisionedAccount && !!this.invite.provisionedAccount.approvedAt;
            },
            expires: function() {
                return moment(this.invite.createdAt).add(7, 'd')
            },
            expired: function() {
                return moment().isAfter(this.expires)
            },
        },
        watch: {
            resetTrigger: function() {
                this.roleAdds = [];
                this.roleRemoves = [];
            },
            saveTrigger: function() {
                if (this.roleAdds.length || this.roleRemoves.length) {
                    this.saveRoles();
                }
            },
        },
        methods: {
            saveRoles: function() {
                const requestUrl = `${siteVars.apiUrl}/invites/${this.invite.id}/initial-sna-roles`

                axios
                    .put(requestUrl, {
                        snaAdds: this.roleAdds,
                        snaRemoves: this.roleRemoves,
                    })
                    .then(() => {
                        this.roleAdds = [];
                        this.roleRemoves = [];
                        this.$emit('rolesUpdated');
                    })
                    .catch(() => {
                        this.$emit('rolesUpdateError');
                    });
            },
        },
        mounted() {
        },
    }
</script>