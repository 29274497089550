<template>
    <v-app id="gathering-admin">
        <v-container class='pa-4'>
            <v-row>
                <v-col v-if='invite && startDate && endDate'>
                    <v-alert
                        border="left"
                        colored-border
                        color="success"
                        elevation="2"
                    >
                        <span v-if='startDate <= todaysDate'>
                            Thank You. Your form has been received.
                            You will receive an email shortly with your login details
                        </span>
                        <span v-else>
                            Thank You. Your form has been received.
                            Your account is scheduled to be made active on the {{formatedStartDate}}. You will receive an email on this date with your login details.
                        </span>
                    </v-alert>
                </v-col>
                <v-col v-else>
                    <v-alert
                        border="left"
                        colored-border
                        color="warning"
                        elevation="2"
                    >
                        <span>
                            Request has been completed or has expired. Please close this window.
                        </span>
                    </v-alert>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
    import moment from "moment";
    export default {
        props: {
            invite: Object,
            startDate: String,
            endDate: String,
            error: [String, Object, Array],
        },
        data: function() {
            return {
            };
        },
        computed: {
            todaysDate: function() {
                return moment().format('YYYY-MM-DD');
            },
            formatedStartDate: function() {
                return moment(this.startDate).format('DD/MM/YYYY');
            },
            formatedEndDate: function() {
                return moment(this.endDate).format('DD/MM/YYYY');
            },
        },
        watch: {
        },
        methods: {
        },
        mounted() {
        },
    }
</script>