<template>
    <v-container v-if='accountType'>
        <v-row>
            <v-col>
                <h4 class='py-6'>Accounts <span class="blue-grey--text darken-1">@ {{accountType.name}}</span></h4>
            </v-col>
        </v-row>
        <v-form v-model="valid">
            <v-row>
                <v-col>
                    <v-text-field
                        v-model="searchString"
                        label="Search..."
                        clearable
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-select
                        v-model="activeOnly"
                        :items="activeOnlyItems"
                        label="Status"
                    ></v-select>
                </v-col>
                <v-col>
                    <v-select
                        v-model="pageSize"
                        :items="pageSizeItems"
                        label="Showing"
                    ></v-select>
                </v-col>
                <v-col>
                    <v-checkbox
                        v-model="useAdvancedRoles"
                        label="Advanced Roles"
                    ></v-checkbox>
                </v-col>
                <!--v-col>
                    <v-select
                        v-model="byRole"
                        :items="byRoleItems"
                        label="ByRole"
                    ></v-select>
                </v-col[-->
                <v-col>
                </v-col>
                <v-col cols='auto'>
                    <v-btn
                        @click='resetTrigger += 1'
                    >Reset</v-btn>
                </v-col>
                <v-col cols='auto'>
                    <v-btn
                        @click='saveTrigger += 1'
                        color="primary"
                    >Save</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-card class='pa-4 soft-shadow mb-15'>
            <v-simple-table class='accounts-table' dense>
                <thead>
                    <tr>
                        <th style='width: 1%'></th>
                        <th style='width: 1%'>Diocesan ID</th>
                        <th
                            v-for="property in inputProperties"
                            :key="property.id"
                            @click="setOrder(property.name)"
                            style='width: 1%; cursor: pointer;'
                        >
                            {{headingLabel(property.name)}}
                            <span v-if='orderProperty == property.name'>
                                <v-icon x-small v-if="orderDirection == 'asc'">mdi-sort-ascending</v-icon>
                                <v-icon x-small v-else>mdi-sort-descending</v-icon>
                            </span>
                        </th>
                        <th style='width: 1%'>Status</th>
                        <th v-if='hasRoles'>Roles</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(account,index) in accounts">
                        <account-row
                            :properties='inputProperties'
                            :accountType='accountType'
                            :account='account'
                            :use-advanced-roles='useAdvancedRoles'
                            :by-role='byRole'
                            :sna-users='snaUsers'
                            :advanced-sna-roles='advancedSnaRoles'
                            :simple-sna-roles='simpleSnaRoles'
                            :key="index"
                            :save-trigger='saveTrigger'
                            :reset-trigger='resetTrigger'
                            @rolesUpdated="rolesUpdated = true"
                            @rolesUpdateError="rolesUpdateError = true"
                            @edit="editAccount($event)"
                        ></account-row>
                    </template>
                </tbody>
            </v-simple-table>
        </v-card>
        <v-snackbar
            top
            right
            v-model='fetchSnaRolesError'
            color='error'
        >
            Could not read available roles.
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    icon
                    v-bind="attrs"
                    @click="fetchSnaRolesError = false"
                >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>


        </v-snackbar>
        <v-snackbar
            top
            right
            v-model='rolesUpdateError'
            color='error'
        >
            Roles could not save. Please refresh the page and try again.
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    icon
                    v-bind="attrs"
                    @click="rolesUpdateError = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar
            top
            right
            v-model='rolesUpdated'
            color='success'
        >
            Roles saved
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    icon
                    v-bind="attrs"
                    @click="rolesUpdated = false"
                >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>


        </v-snackbar>
        <v-snackbar
            top
            right
            v-model='accountUpdated'
            color='success'
        >
            Account saved
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    icon
                    v-bind="attrs"
                    @click="accountUpdated = false"
                >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>


        </v-snackbar>

        <account-edit-dialog
            v-if="editingAccount != null"
            :account='editingAccount'
            :account-type='accountType'
            @saved='fetch(); editingAccount = null'
            @close='editingAccount = null'
            @showArchived='showingArchived = $event'
        ></account-edit-dialog>

        <account-edit-dialog
            v-if="showingArchived != null"
            readonly
            :account='showingArchived'
            :account-type='accountType'
            @saved='fetch(); showingArchived = null'
            @close='showingArchived = null'
        ></account-edit-dialog>
    </v-container>
</template>

<script>
    import AccountRow from './AccountRow.vue';
    import AccountEditDialog from './AccountEditDialog.vue';
    import _ from 'lodash';
    import siteVars from '../../site-vars';
    import axios from 'axios';

    export default {
        components: {
            AccountRow,
            AccountEditDialog,
        },
        props: {
            accountType: Object,
        },
        data: () => ({
            valid: true,
            searchString: '',
            orderProperty: '',
            orderDirection: 'asc',
            advancedSnaRoles: null,
            simpleSnaRoles: null,
            snaUsers: null,
            activeOnly: true,
            pageSize: 20,
            accounts: null,
            accountsMeta: null,
            // Update Flags
            fetchSnaRolesError: false,
            rolesUpdateError: false,
            rolesUpdated: false,
            accountUpdated: false,
            editingAccount: null,
            showingArchived: null,
            useAdvancedRoles: false,
            byRole: null,
            // Child component triggers
            saveTrigger: 0,
            resetTrigger: 0,
        }),
        computed: {
            inputProperties: function() {
                return this.accountType.properties.filter(p => p.type !== 'null');
            },
            hasRoles: function() {
                return this.advancedSnaRoles && this.advancedSnaRoles.length;
            },
            activeOnlyItems: function() {
                return [
                    {
                        value: true,
                        text: 'Active Only',
                    },
                    {
                        value: false,
                        text: 'Inactive',
                    },
                    {
                        value: null,
                        text: 'All Accounts',
                    },
                ];
            },
            byRoleItems: function() {
                return [];
            },
            pageSizeItems: function() {
                return [
                    {
                        value: 10,
                        text: '10 items',
                    },
                    {
                        value: 20,
                        text: '20 items',
                    },
                    {
                        value: 50,
                        text: '50 items',
                    },
                    {
                        value: 100,
                        text: '100 items',
                    },
                    {
                        value: 200,
                        text: '200 items',
                    },
                ];
            },
        },
        watch: {
            accountType: function(newVal) {
                if (newVal) {
                    this.fetch();
                }
            },
            searchString: function() {
                this.fetch();
            },
            orderProperty: function() {
                this.fetch();
            },
            orderDirection: function() {
                this.fetch();
            },
            pageSize: function() {
                this.fetch();
            },
            activeOnly: function() {
                this.fetch();
            },
            rolesUpdated: function() {
                this.fetch();
            },
        },
        methods: {
            fetch: function() {
                const requestUrl = `${siteVars.apiUrl}/account-types/${this.accountType.id}/accounts`

                axios
                    .get(requestUrl, {
                        params: {
                            searchString: this.searchString,
                            orderProperty: this.orderProperty,
                            orderDirection: this.orderDirection,
                            pageSize: this.pageSize,
                            activeOnly: this.activeOnly,
                        }
                    })
                    .then(response => {
                        this.accounts = response.data.data;
                        this.accountsMeta = response.data.meta;

                        this.fetchSnaAccounts(this.accounts.map(a => a.identityId));
                    });
            },
            fetchSnaAccounts: function(identityIds) {
                const requestUrl = `${siteVars.apiUrl}/account-types/${this.accountType.id}/sna-accounts`

                axios
                    .post(requestUrl, {
                        ids: identityIds
                    })
                    .then(response => {
                        this.advancedSnaRoles = response.data.advancedRoles;
                        this.simpleSnaRoles = response.data.simpleRoles;
                        this.snaUsers = response.data.users;
                    })
                    .catch((/*error*/) => {
                        this.fetchSnaRolesError = true;
                    });
            },
            headingLabel: function(name) {
                return _.startCase(name);
            },
            editAccount: function(account) {
                console.log({account});
                this.editingAccount = account;
            },
            setOrder: function(orderProperty) {
                if (this.orderProperty == orderProperty) {
                    if (this.orderDirection == 'asc') {
                        this.orderDirection = 'desc';
                    } else {
                        this.orderProperty = null;
                        this.orderDirection = 'asc';
                    }
                } else {
                    this.orderProperty = orderProperty;
                    this.orderDirection == 'asc';
                }
            },
        },
        mounted() {
            this.fetch();
        },
    }
</script>

<style>
    .accounts-table tr th {
        font-size: 0.875rem !important;
        font-weight: normal !important;
        color: #999 !important;
        text-transform: uppercase !important;
        line-height: 3 !important;
        white-space: nowrap;
    }

    .accounts-table tr td {
        color: #666 !important;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.5;
    }

    .accounts-table tr {
        height: 45px;
    }

    .accounts-table tbody > tr:hover {
        background: #ffd !important;
    }

    .theme--light.v-data-table tbody tr:not(:last-child) > td,
    .theme--light.v-data-table thead tr > th {
        border-bottom: none !important;
    }

    .soft-shadow {
        color: #666;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08) !important;
    }

</style>