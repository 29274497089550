import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        authToken: null,
        previousAuth: false,
    },
    mutations: {
        authToken(state, authToken) {
            state.authToken = authToken;
            state.previousAuth = true;
        },
    },
    getters: {
        authToken: (state) => {
            return state.authToken;
        },
        previousAuth: (state) => {
            return state.previousAuth;
        },
    },
    actions: {
    },
    modules: {
    }
})
