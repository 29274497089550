import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'

import axios from 'axios'

Vue.config.productionTip = false

// Redirect to provider login
axios.interceptors.response.use(
    response => response,
    error => {
        const {status, data} = error.response;
        if (status === 401 && data.error == 'Unauthenticated.') {
            store.commit('authToken', null);
        }
        if (status === 403) {
            window.alert(data.error);
        }
        return Promise.reject(error);
    }
);

// Inject token
axios.interceptors.request.use(function (config) {
    const token = store.getters.authToken;

    config.headers.Authorization = 'Bearer ' + token;

    return config;
});

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
