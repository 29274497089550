<template>
    <v-dialog
        v-if='previousAuth'
        :value="true"
        persistent
        max-width="800"
        min-width="400"
    >
        <v-card class="elevation-12">
            <v-toolbar
                color="#333"
                dark
                flat
            >
                <v-toolbar-title>Session Expired</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class='pt-5'>
                The session has expired. Please refresh the session to login.
            </v-card-text>
            <v-card-actions class='pa-4'>
                <v-spacer></v-spacer>
                <v-btn type="button" @click="providerLogin()" color="primary" class="white--text" >Refresh session</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapGetters } from 'vuex';
    import siteVars from '../../site-vars';

    export default {
        name: 'login',
        props: {
        },
        data() {
            return {
            };
        },
        computed: {
            ...mapGetters({
                previousAuth: 'previousAuth',
            }),
        },
        methods: {
            providerLogin() {
                window.location = siteVars.providerLogin;
            }
        },
        mounted() {
            if (!this.previousAuth) {
                this.providerLogin();
            }
        },
    }
</script>


<style>
    .v-card.v-sheet.theme--light {
        border: 0;
    }
</style>