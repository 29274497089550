<template>
    <tr>
        <td class="px-0">
            <v-btn fab small @click="$emit('edit', account)" text-color="primary" color="white" elevation="0">
                <v-icon dark>
                    mdi-pencil
                </v-icon>
            </v-btn>
        </td>

        <td :title="snaUserTooltip">
            <span v-if='snaUser' class='grey--text text--darken-2'>{{snaUser.diocesanId}}</span>
            <span v-else-if='account.externalUid' class='grey--text text--darken-2'>{{account.externalUid}}</span>
            <span v-else class='grey--text'>&lt;pending&gt;</span>
        </td>

        <td
            v-for="property in properties"
            :key="property.id"
            v-html="account.state[property.name]"
            class='text-no-wrap'
        ></td>

        <td>
            <span v-if='account.isActive' class='success--text'>active</span>
            <span v-else class='warning--text'>inactive</span>
        </td>

        <td v-if='snaUser'>
            <role-selector
                v-if="(useAdvancedRoles && advancedSnaRoles.length > 0) || (!useAdvancedRoles && simpleSnaRoles.length > 0)"
                :adds.sync='roleAdds'
                :removes.sync='roleRemoves'
                :account-type='accountType'
                :advanced-sna-roles='advancedSnaRoles'
                :simple-sna-roles='simpleSnaRoles'
                :initial-roles='snaUser.roles'
                :use-advanced-roles='useAdvancedRoles'
                :by-role='byRole'
                :save-trigger='saveTrigger'
                :reset-trigger='resetTrigger'
            ></role-selector>
            <em v-else class='grey--text'>-</em>
            <!--v-autocomplete
                v-model="roles"
                :items="roleSelectItems"
                chips
                deletable-chips
                small-chips
                label=""
                no-data-text="You do not access to assign roles"
                multiple
                hide-details
                class='pt-0 mt-0'
                @change='saveRoles'
            >
                <template #selection="{ item }">
                    <v-chip
                        small
                        color="primary"
                        @click:close="removeItem(item)"
                        label
                    >{{item.text}}</v-chip>
                </template>
            </v-autocomplete-->
        </td>
        <td v-else>
            <em class='grey--text'>-</em>
        </td>
    </tr>
</template>

<script>
    import RoleSelector from './form-elements/RoleSelector';
    import siteVars from '../../site-vars';
    import axios from 'axios';

    export default {
        components: {
            RoleSelector,
        },
        props: [
            'accountType',
            'useAdvancedRoles',
            'byRole',
            'account',
            'snaUsers',
            'properties',
            'advancedSnaRoles',
            'simpleSnaRoles',
            'resetTrigger',
            'saveTrigger',
        ],
        data: function() {
            return {
                valid: true,
                name: '',
                roleAdds: [],
                roleRemoves: [],
                activeOnly: '',
                pageSize: 20,
                archivedAccount: null,
            };
        },
        computed: {
            snaUser: function() {
                if (!this.snaUsers) {
                    return null;
                }

                return this.snaUsers.find(u => this.account.identityId == parseInt(u.externalId));
            },
            snaUserTooltip: function() {
                if (!this.snaUsers) {
                    return undefined;
                }

                const json = JSON.stringify(this.snaUser, null, 4);

                return json ? json.replace(/["[\],{}]/g,'') : undefined;
            },
            roleSelectItems: function() {
                var returnRoles = [];

                if (!this.snaUser) {
                    return [];
                }

                this.advancedSnaRoles.map(group => {
                    returnRoles.push({
                        header: group.title,
                    });

                    group.items.map(item => {
                        returnRoles.push({
                            value: item.id,
                            text: item.id,
                            description: item.description,
                        });
                    });
                });

                return returnRoles;
            },
        },
        watch: {
            resetTrigger: function() {
                this.roleAdds = [];
                this.roleRemoves = [];
            },
            saveTrigger: function() {
                if (this.roleAdds.length || this.roleRemoves.length) {
                    this.saveRoles();
                }
            },
        },
        methods: {
            removeItem: function(item) {
                var found = this.roles.findIndex(s => s == item.value);
                if (found !== -1) {
                    this.roles.splice(found, 1);
                }
            },
            saveRoles: function() {
                const requestUrl = `${siteVars.apiUrl}/accounts/${this.account.id}/assign-sna-roles/${this.snaUser.id}`

                axios
                    .post(requestUrl, {
                        snaAdds: this.roleAdds,
                        snaRemoves: this.roleRemoves,
                    })
                    .then(() => {
                        this.roleAdds = [];
                        this.roleRemoves = [];
                        this.$emit('rolesUpdated');
                    })
                    .catch(() => {
                        this.$emit('rolesUpdateError');
                    });
            },
        },
        mounted() {
            this.roles = this.account.snaRoles;
        },
    }
</script>