<template>
    <v-form v-if='editingProperty' ref='form' v-model='valid' @submit.prevent="saveProperty">
        <v-row>
            <v-col>
                <v-row class='mt-10'>
                    <v-col cols='6' sm='3' md='1'>
                        <v-select
                            v-model="editingProperty.priorityIndex"
                            :items='priorityIndexes'
                            dense
                            :rules="[]"
                            label="Priority"
                            hint=""
                            required
                        ></v-select>
                    </v-col>
                    <v-col cols='12' sm='6' md='3'>
                        <v-text-field
                            v-model="editingProperty.name"
                            dense
                            :rules="propertyNameRules"
                            label="Name"
                            hint="Use camel case (eg. givenName)"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols='12' sm='6' md='3'>
                        <v-select
                            v-model="editingProperty.jsonTypeId"
                            :items='jsonTypes'
                            dense
                            :rules="[]"
                            label="Type"
                            hint="Use string for dates. Use null for message box."
                            required
                        ></v-select>
                    </v-col>
                    <v-col cols='12' sm='6' md='3'>
                        <v-select
                            v-model="editingProperty.canOwnerEdit"
                            :items='canOwnerEditItems'
                            dense
                            :rules="[]"
                            label="Visibility"
                            hint="Use string for dates. Use null for message box."
                        ></v-select>
                    </v-col>
                    <v-col cols='12' class='ma-0 pa-0'></v-col>
                    <v-col class='mt-n5'>
                        <v-btn icon small class='ml-11' @click="showOptionsHelp = true"><v-icon small>mdi-information</v-icon></v-btn>
                        <v-textarea
                            v-model="optionsJson"
                            rows='2'
                            class='mt-n2'
                            auto-grow
                            dense
                            :rules="propertyOptionsRules"
                            label="Options"
                            hint="JSON Options"
                            required
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols='auto'>
                <v-row class='mt-10'>
                    <v-col>
                        <v-btn @click='saveProperty' small style='min-width: 80px;' color='primary'>Save</v-btn>
                    </v-col>
                </v-row>
                <v-row class='mt-10'>
                    <v-col>
                        <v-btn @click='deleteConfirm = true' v-if='!deleteConfirm' small style='min-width: 80px;' color='warning'>Delete</v-btn>
                        <v-btn @click='deleteProperty' v-if='deleteConfirm' small style='min-width: 80px;' color='red'>Confirm!</v-btn>
                    </v-col>
                </v-row>
                <v-row v-if='deleteConfirm' class='mt-1'>
                    <v-col>
                        <v-btn @click='deleteConfirm = false' small style='min-width: 80px;'>Cancel</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-dialog
                v-model="showOptionsHelp"
                max-width="860"
            >
                <v-card>
                    <v-card-title class="headline">
                        Options Help
                    </v-card-title>

                    <v-card-text>
                        <div>
                             <dl>
                                <dt class='font-weight-bold'>label</dt>
                                <dd class='ml-5'>A custom label to be displayed above the input. The label will default to the field name (title case).</dd>
                                <dt class='font-weight-bold'>hint</dt>
                                <dd class='ml-5'>A hint to be displayed below the input when the input is selected.</dd>
                                <dt class='font-weight-bold'>enum</dt>
                                <dd class='ml-5'>An array of options which will cause the attribtue to become a select box. This must be an array of values of the same type as the attribute.</dd>
                                <dt class='font-weight-bold'>labels</dt>
                                <dd class='ml-5'>When using enum (above), this array of strings is used as the option labels. By default, the enum array is used.</dd>
                                <dt class='font-weight-bold'>message</dt>
                                <dd class='ml-5'>If the property type is 'null' and this field is set, it will display an alert information message in the place of the attribute.</dd>
                                <dt class='font-weight-bold'>rules</dt>
                                <dd class='ml-5'>
                                    An object keyed by validation rules and values as the message. For example: {"required": "This field is required"}. Rules include:
                                    <div><strong>"required"</strong> - indicates a value is required</div>
                                    <div><strong>"email"</strong> - an email address (domain validated)</div>
                                    <div><strong>"date"</strong> - an iso date (YYYY-MM-DD)</div>
                                    <div><strong>"auDate"</strong> - an AU localized date (DD/MM/YYYY)</div>
                                    <div><strong>"regex:{expression}"</strong> - a regular expression</div>
                                </dd>
                                <dt class='font-weight-bold'>cols</dt>
                                <dd class='ml-5'>Number of columns (out of 12) to display the input.</dd>
                                <dt class='font-weight-bold'>xs</dt>
                                <dd class='ml-5'>Number of columns (out of 12) to display the input for when the screen is extra small (&lt; 600px).</dd>
                                <dt class='font-weight-bold'>sm</dt>
                                <dd class='ml-5'>Number of columns (out of 12) to display the input for when the screen is small (600px &gt; &lt; 960px).</dd>
                                <dt class='font-weight-bold'>md</dt>
                                <dd class='ml-5'>Number of columns (out of 12) to display the input for when the screen is medium (960px &gt; &lt; 1264px).</dd>
                                <dt class='font-weight-bold'>lg</dt>
                                <dd class='ml-5'>Number of columns (out of 12) to display the input for when the screen is large (1264px &gt; &lt; 1904px).</dd>
                                <dt class='font-weight-bold'>xl</dt>
                                <dd class='ml-5'>Number of columns (out of 12) to display the input for when the screen is extra large (&gt; 1904px).</dd>
                                <dt class='font-weight-bold'>format</dt>
                                <dd class='ml-5'>Used for strings to determine input format. "date" is currently the only supported format.</dd>
                                <dt class='font-weight-bold'>minDate</dt>
                                <dd class='ml-5'>If "format" is set to date, the supplied minDate (ISO YYYY-MM-DD) will be used a minimum date for the input widget.</dd>
                                <dt class='font-weight-bold'>maxDate</dt>
                                <dd class='ml-5'>If "format" is set to date, the supplied maxDate (ISO YYYY-MM-DD) will be used a maximum date for the input widget. The maximum defaults to now().</dd>
                                <dt class='font-weight-bold'>yearFirst</dt>
                                <dd class='ml-5'>If "format" is set to date, the supplied yearFirst (boolean) will make the input widget ask for the year first (good for selecting date of birth).</dd>
                                <dt class='font-weight-bold'>defaultMonth</dt>
                                <dd class='ml-5'>If "format" is set to date, the supplied defaultMonth (YYYY-MM) will be make the input widget start as the specified month.</dd>
                                <dt class='font-weight-bold'>dateFormat</dt>
                                <dd class='ml-5'>If "format" is set to date, the supplied dateFormat (eg. "DD/MM/YYYY") will used as the input format for the date widget.</dd>
                            </dl>
                        </div>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn
                            color="grey"
                            text
                            @click="showOptionsHelp = false"
                        >
                            <span>Close</span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-form>
</template>

<script>
    import _ from 'lodash';
    import siteVars from '../../site-vars';
    import axios from 'axios';

    export default {
        props: {
            accountType: Object,
            property: Object,
        },
        data: function() {
            return {
                activeTab: 0,
                editingProperty: null,
                optionsJson: null,
                showOptionsHelp: null,
                valid: true,
                deleteConfirm: false,
            }
        },
        computed: {
            propertyNameRules: function() {
                return [
                    v => !!v || 'Name is required',
                    v => /[a-zA-z_][a-zA-Z0-9_]*/.test(v) || 'Name must be only alpha numeric and underscores',
                ]
            },
            propertyOptionsRules: function() {
                return [
                    v => !!v || 'Options is required',
                    v => {
                        try {
                            var object = JSON.parse(v);
                            return _.isObject(object) || 'Options must be JSON object.';
                        } catch (e) {
                            return e.message;
                        }
                    },
                ]
            },
            jsonTypes: function() {
                return [
                    {
                        text: 'string',
                        value: 1,
                    },
                    {
                        text: 'number',
                        value: 2,
                    },
                    {
                        text: 'integer',
                        value: 3,
                    },
                    {
                        text: 'object',
                        value: 4,
                    },
                    {
                        text: 'array',
                        value: 5,
                    },
                    {
                        text: 'boolean',
                        value: 6,
                    },
                    {
                        text: 'null',
                        value: 7,
                    },
                ]
            },
            priorityIndexes: function() {
                return [...Array(99).keys()].map(k => k + 1);
            },
            canOwnerEditItems: function() {
                return [
                    {
                        text: 'Owner Edit',
                        value: true,
                    },
                    {
                        text: 'Admin Only',
                        value: false,
                    },
                ];
            },
        },
        watch: {
        },
        methods: {
            saveProperty: function() {
                var url;
                var method;

                if (this.editingProperty.id) {
                    url = `${siteVars.apiUrl}/properties/${this.editingProperty.id}`;
                    method = 'put';
                } else {
                    url = `${siteVars.apiUrl}/account-types/${this.accountType.id}/properties/create`;
                    method = 'post';
                }

                axios({
                    method,
                    url,
                    data: {
                        priorityIndex: this.editingProperty.priorityIndex,
                        name: this.editingProperty.name,
                        jsonTypeId: this.editingProperty.jsonTypeId,
                        canOwnerEdit: this.editingProperty.canOwnerEdit,
                        options: JSON.parse(this.optionsJson),
                    }
                })
                    .then(() => {
                        this.$emit('saved');
                    });
            },
            deleteProperty: function() {
                const requestUrl = `${siteVars.apiUrl}/properties/${this.editingProperty.id}`

                axios
                    .delete(requestUrl, {
                    })
                    .then(() => {
                        this.$emit('deleted');
                    });
            },
        },
        mounted() {
            this.editingProperty = _.clone(this.property);
            this.optionsJson = JSON.stringify(this.editingProperty.options, null, 4);
        },
    }
</script>