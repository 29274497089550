<template>
    <v-container class='pa-4'>
        <v-row>
            <v-col>
                <h4 class='py-6'>Manually Add Account</h4>
            </v-col>
        </v-row>

        <template v-if='accountCreated'>
            <v-alert
                dense
                text
                dismissible
                type="success"
                class="mb-10"
            >
                Account scheduled to be created.
            </v-alert>


            <v-btn @click="accountCreated = false">Create Another</v-btn>
        </template>
        <template v-else>

            <v-alert
                v-for='(errorLines, property) in saveErrors'
                :key='property'
                dense
                text
                dismissible
                type="error"
                class="mb-10"
            >
                <div class="title">{{property}}</div>
                <div v-for="(errorLine, index) in errorLines" :key='index'>
                    {{errorLine}}
                </div>
            </v-alert>

            <v-form ref="createAccountForm" v-model="formValid" @submit.prevent="saveAccount()">
                <v-row>
                    <v-col cols='12' v-if='accountType.introBlurb'>
                        <v-alert
                            border="left"
                            colored-border
                            color="primary"
                            elevation="2"
                        >
                            {{accountType.introBlurb}}
                        </v-alert>
                    </v-col>

                    <v-col cols='12'>
                        <v-text-field
                            dense
                            label='Email'
                            v-model='account.personalEmail'
                            :rules='[rules.required, rules.email]'
                        ></v-text-field>
                    </v-col>

                    <v-col cols='6'>
                        <date-input
                            dense
                            v-model="account.startDate"
                            :rules='[rules.required, rules.auDate]'
                            :options="{label: 'Starting', maxDate: maxStartDate}"
                        >
                        </date-input>
                    </v-col>
                    <v-col cols='6'>
                        <date-input
                            dense
                            v-model="account.endDate"
                            :rules='[rules.required, rules.auDate]'
                            :options="{label: 'Ending', minDate: startDate, maxDate: maxEndDate}"
                        >
                        </date-input>
                    </v-col>

                    <v-col class='mt-3'>
                        <role-selector
                            v-if='advancedSnaRoles'
                            label='Initial Roles'
                            :adds.sync='initialSnaRoles'
                            :removes='[]'
                            :account-type='accountType'
                            :advanced-sna-roles='advancedSnaRoles'
                            :simple-sna-roles='simpleSnaRoles'
                            :initial-roles='[]'
                            :use-advanced-roles='useAdvancedRoles'
                        ></role-selector>
                    </v-col>

                    <v-col cols='auto'>
                        <v-checkbox
                            dense
                            v-model='useAdvancedRoles'
                            label='Advanced Roles'
                        >
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        v-for="property in accountType.properties"
                        :key='property.id'
                        :cols='property.options.cols || 12'
                        :xs='property.options.xs'
                        :sm='property.options.sm'
                        :md='property.options.md'
                        :lg='property.options.lg'
                        :xl='property.options.xl'
                    >
                        <form-input
                            :type='property.type'
                            v-model='account.state[property.name]'
                            :options='property.options'
                            :name='property.name'
                        ></form-input>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-btn type='submit' color='primary'>Add Account</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </template>
    </v-container>
</template>

<script>
    import moment from "moment";
    import validationRules from "./form-elements/validation-rules";
    import DateInput from './form-elements/DateInput.vue';
    import FormInput from './form-elements/FormInput.vue';
    import siteVars from '../../site-vars';
    import axios from 'axios';
    import RoleSelector from './form-elements/RoleSelector';

    export default {
        components: {
            RoleSelector,
            DateInput,
            FormInput,
        },
        props: {
            accountType: Object,
        },
        data: function() {
            return {
                account: this.newAccount(),
                accountCreated: false,
                formValid: false,
                initialSnaRoles: [],
                useAdvancedRoles: false,
                advancedSnaRoles: null,
                simpleSnaRoles: null,
                fetchSnaRolesError: false,
                saveErrors: null,
            };
        },
        computed: {
            startDate: function() {
                return this.account.startDate;
            },
            endDate: function() {
                return this.account.endDate;
            },
            rules: function() {
                return validationRules;
            },
            maxStartDate: function() {
                return moment().add(365, 'days').format('YYYY-MM-DD');
            },
            maxEndDate: function() {
                return moment(this.startDate).add(this.accountType.maxDurationDays - 1, 'days').format('YYYY-MM-DD');
            },
        },
        watch: {
            startDate: function(newVal, oldVal) {
                // Move endDate by same offset, if set
                if (this.endDate && oldVal) {
                    var diff = moment(this.endDate).diff(moment(oldVal), 'days');
                    this.account.endDate = moment(newVal).add(diff, 'days').format('YYYY-MM-DD');
                }
            }
        },
        methods: {
            fetchSnaRoles: function() {
                const requestUrl = `${siteVars.apiUrl}/account-types/${this.accountType.id}/available-sna-roles`

                this.fetchSnaRolesError = false;

                axios
                    .get(requestUrl, {
                    })
                    .then(response => {
                        this.advancedSnaRoles = response.data.advanced;
                        this.simpleSnaRoles = response.data.simple;
                    })
                    .catch((/*error*/) => {
                        this.fetchSnaRolesError = true;
                    });
            },
            saveAccount: function() {
                this.$refs.createAccountForm.validate();

                this.saveErrors = null;

                this.$nextTick(() => {
                    if (this.formValid) {

                        const requestUrl = `${siteVars.apiUrl}/account-types/${this.accountType.id}/accounts/create`

                        console.log({
                                personalEmail: this.account.personalEmail,
                                initialSnaRoles: this.initialSnaRoles,
                                state: this.account.state,
                                startDate: this.account.startDate,
                                endDate: this.account.endDate,
                            });

                        axios
                            .post(requestUrl, {
                                personalEmail: this.account.personalEmail,
                                initialSnaRoles: this.initialSnaRoles,
                                state: this.account.state,
                                startDate: this.account.startDate,
                                endDate: this.account.endDate,
                            })
                            .then(() => {
                                this.accountCreated = true;
                                this.formValid = true;
                                this.account = this.newAccount();
                                this.initialSnaRoles = [];
                                this.$refs.form.reset();

                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            })
                            .catch((error) => {
                                if (error.response.status == '422') {
                                    this.saveErrors = error.response.data.errors;

                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                }
                            });
                    } else {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }
                });

            },
            formatDate: function (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${day}/${month}/${year}`
            },
            parseDate: function (date) {
                if (!date) return null

                const [day, month, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            newAccount: function () {
                return {
                    personalEmail: '',
                    state: {},
                    startDate: new Date().toISOString().substr(0, 10),
                    endDate: null,
                };
            },
        },
        mounted() {
            this.fetchSnaRoles();
        },
    }
</script>