import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
    directives: {
        Ripple
    }
});

// import colors from 'vuetify/lib/util/colors'

export default new Vuetify({
    // theme: {
    //     themes: {
    //         light: {
    //             primary: colors.purple,
    //             secondary: colors.grey.darken1,
    //             accent: colors.shades.black,
    //             error: colors.red.accent3,
    //         },
    //         dark: {
    //             primary: colors.blue.lighten3,
    //         },
    //     },
    // },
});
