<template>
    <v-container class='pa-4' v-if='editingAccountType'>
        <v-tabs
            v-model='activeTab'
        >
            <v-tab>Base settings</v-tab>
            <v-tab>Syncnova Integration</v-tab>
            <v-tab>Attributes</v-tab>
        </v-tabs>
        <v-row v-if='activeTab == 0' dense>
            <v-col cols='12'>
                <h3 class='my-4'>
                    Base settings
                </h3>
            </v-col>

            <v-col>
                <v-text-field
                    v-model="editingAccountType.name"
                    dense
                    :rules="[]"
                    label="Name"
                    required
                ></v-text-field>
            </v-col>

            <v-col cols='auto'>
                <v-select
                    v-model="editingAccountType.isActive"
                    :items="[{value: true, text: 'Active'}, {value: false, text: 'Disabled'}]"
                    dense
                    :rules="[]"
                    label="Active"
                    required
                ></v-select>
            </v-col>

            <v-col class='ma-0 pa-0' cols='12'></v-col>

            <v-col cols='auto'>
                <v-select
                    v-model="editingAccountType.isInviteAutoApprove"
                    :items="[{value: true, text: 'Yes'}, {value: false, text: 'No'}]"
                    dense
                    :rules="[]"
                    label="Is Invite Auto Approve"
                    hint="Should the external user's invite response automatically provision an account?"
                ></v-select>
            </v-col>
            <v-col cols='auto'>
                <v-select
                    v-model="editingAccountType.canOwnerSetSchedule"
                    :items="[{value: true, text: 'Yes'}, {value: false, text: 'No'}]"
                    dense
                    :rules="[]"
                    label="Owner Set Start / End"
                    hint="Can the owner (external user) set the Start / End date when creating the account?"
                ></v-select>
            </v-col>

            <v-col>
                <v-text-field
                    v-model="editingAccountType.maxDurationDays"
                    dense
                    :rules="[]"
                    label="Max account duration (days)"
                    type='number'
                    step='1'
                    required
                ></v-text-field>
            </v-col>

            <v-col cols='12'>
                <v-textarea
                    v-model="editingAccountType.introBlurb"
                    rows="2"
                    auto-grow
                    dense
                    :rules="[]"
                    label="Introduction blurb"
                    hint="This will appear to the account manager above all forms when creating accounts"
                ></v-textarea>
            </v-col>
            <v-col class='mt-10' cols='12'>
                <v-btn
                    color="primary"
                    type='Submit'
                    @click="saveBaseSettings"
                >
                    Save
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if='activeTab == 1' dense>

            <v-col cols='12'>
                <h3 class='my-4'>
                    Syncnova Integration
                </h3>
            </v-col>
            <v-col cols='12' sm='6' md='3'>
                <v-text-field
                    v-model="editingAccountType.snaSite"
                    dense
                    :rules="[]"
                    label="Site ID"
                    hint="Site ID as configured in SNA (eg. staff_trinity)"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols='12' sm='6' md='3'>
                <v-text-field
                    v-model="editingAccountType.snaBaseAccess"
                    dense
                    :rules="[]"
                    label="Base Access"
                    hint="If set, users must have this access in order to see the site in the dropdown. When blank, any user may access if they they have access to one of the other access groups."
                ></v-text-field>
            </v-col>
            <v-col cols='12' sm='6' md='3'>
                <v-text-field
                    v-model="editingAccountType.snaAccessAdmin"
                    dense
                    :rules="[]"
                    label="Admin"
                    hint="Access to all functions of external accounts for this site."
                ></v-text-field>
            </v-col>
            <v-col cols='12' sm='6' md='3'>
                <v-text-field
                    v-model="editingAccountType.snaAccessViewer"
                    dense
                    :rules="[]"
                    label="Viewer"
                    hint="Access to view accounts only"
                ></v-text-field>
            </v-col>
            <v-col cols='12' sm='6' md='3'>
                <v-text-field
                    v-model="editingAccountType.snaAccessInvite"
                    dense
                    :rules="[]"
                    label="Invite"
                    hint="Access to invite people to create an account and the ability to approve the response"
                ></v-text-field>
            </v-col>
            <v-col cols='12' sm='6' md='3'>
                <v-text-field
                    v-model="editingAccountType.snaAccessApprove"
                    dense
                    :rules="[]"
                    label="Approve"
                    hint="Access to only approve invite responses"
                ></v-text-field>
            </v-col>
            <v-col cols='12' sm='6' md='3'>
                <v-text-field
                    v-model="editingAccountType.snaAccessManual"
                    dense
                    :rules="[]"
                    label="Manual"
                    hint="Access to manually add new accounts"
                ></v-text-field>
            </v-col>
            <v-col cols='12' sm='6' md='3'>
                <v-text-field
                    v-model="editingAccountType.snaAccessImport"
                    dense
                    :rules="[]"
                    label="Import"
                    hint="Access to import accounts from a CSV file"
                ></v-text-field>
            </v-col>
            <v-col class='mt-10' cols='12'>
                <v-btn
                    color="primary"
                    type='Submit'
                    @click="saveSyncnovaSettings"
                >
                    Save
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if='activeTab == 2' dense>
            <v-col cols='12'>
                <h3 class='my-4'>
                    Account Attributes
                </h3>
            </v-col>
            <v-col cols='12'>
                <settings-property
                    v-for='property in editingAccountType.properties'
                    :key='property.id'
                    :account-type='editingAccountType'
                    :property='property'
                    @saved='fetchAccountType'
                    @deleted='fetchAccountType'
                >
                </settings-property>
            </v-col>
            <v-col v-if='!addingNew' cols='12'>
                <v-btn @click='addingNew = true'>Add Attribute</v-btn>
            </v-col>
            <v-col v-else cols='12'>
                <h3>New Attribute</h3>
                <settings-property
                    :account-type='editingAccountType'
                    :property='{}'
                    @saved='fetchAccountType'
                    @deleted='fetchAccountType'
                >
                </settings-property>
            </v-col>
        </v-row>
        <template v-if='validationErrors'>
            <v-row v-for='(validationError,index) in validationErrors' :key='index'>
                <v-col>
                    <v-alert
                        border="left"
                        colored-border
                        color="error"
                        elevation="2"
                    >
                        {{validationError}}
                    </v-alert>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
    import siteVars from '../../site-vars';
    import axios from 'axios';
    import settingsProperty from './SettingsProperty.vue';

    export default {
        components: {
            settingsProperty,
        },
        props: {
            accountType: Object,
        },
        data: function() {
            return {
                activeTab: 0,
                editingAccountType: null,
                validationErrors: null,
                addingNew: false,
            }
        },
        computed: {
        },
        watch: {
        },
        methods: {
            saveBaseSettings: function() {
                const requestUrl = `${siteVars.apiUrl}/account-types/${this.editingAccountType.id}/base`

                axios
                    .put(requestUrl, {
                        name: this.editingAccountType.name,
                        isActive: this.editingAccountType.isActive,
                        isInviteAutoApprove: this.editingAccountType.isInviteAutoApprove,
                        canOwnerSetSchedule: this.editingAccountType.canOwnerSetSchedule,
                        maxDurationDays: this.editingAccountType.maxDurationDays,
                        introBlurb: this.editingAccountType.introBlurb,
                    })
                    .then(() => {
                        this.$emit('saved');
                    });
            },
            saveSyncnovaSettings: function() {
                const requestUrl = `${siteVars.apiUrl}/account-types/${this.editingAccountType.id}/sna`

                axios
                    .put(requestUrl, {
                        snaSite: this.editingAccountType.snaSite,
                        snaBaseAccess: this.editingAccountType.snaBaseAccess,
                        snaAccessAdmin: this.editingAccountType.snaAccessAdmin,
                        snaAccessViewer: this.editingAccountType.snaAccessViewer,
                        snaAccessInvite: this.editingAccountType.snaAccessInvite,
                        snaAccessApprove: this.editingAccountType.snaAccessApprove,
                        snaAccessManual: this.editingAccountType.snaAccessManual,
                        snaAccessImport: this.editingAccountType.snaAccessImport,
                    })
                    .then(() => {
                        this.$emit('saved');
                    });
            },
            fetchAccountType: function() {
                const requestUrl = `${siteVars.apiUrl}/account-types/${this.accountType.id}`

                this.addingNew = false;

                axios
                    .get(requestUrl, {
                        params: {
                            admin: true,
                        },
                    })
                    .then(response => {
                        this.editingAccountType = response.data.data;
                    });
            },
        },
        mounted() {
            this.fetchAccountType();
        },
    }
</script>