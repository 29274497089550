<template>
    <v-menu
        dense
        ref='dateMenu'
        v-model="dateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
        :disabled="readonly"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                dense
                :value="dateFormatted"
                :label="options.label || name"
                :hint="options.hint"
                :rules="rules"
                :readonly="true"
                :error="validationErrors.length > 0"
                :error-messages="validationErrors"
                prepend-icon="event"
                v-bind="attrs"
                v-on="on"
                @blur="date = parseDate(dateFormatted)"
            ></v-text-field>
        </template>
        <v-date-picker
            ref='picker'
            :max="options.maxDate || new Date().toISOString().substr(0, 10)"
            :min="options.minDate"
            :value="value"
            :picker-date.sync="pickerMonth"
            @change="saveDate"
        ></v-date-picker>
    </v-menu>
</template>

<script>
    import moment from "moment";

    export default {
        props: {
            options: Object,
            readonly: Boolean,
            rules: Array,
            name: String,
            value: [String, Number, Array, Object, null],
            errors: [Array, null],
        },
        data: function() {
            return {
                dateMenu: false,
                dateFormatted: this.formatDate(this.value),
                pickerMonth: null,
            };
        },
        computed: {
            validationErrors: function() {
                return this.errors || [];
            },
            defaultMonth: function() {
                if (this.value) {
                    return this.value.substr(0,7);
                }

                return this.options.defaultMonth || new Date().toISOString().substr(0, 7);
            },
        },
        watch: {
            dateMenu: function (newVal) {
                newVal && this.options.yearFirst && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
            },
            value: function (newVal) {
                this.dateFormatted = this.formatDate(newVal);
            },
        },
        methods: {
            saveDate (date) {
                this.$refs.dateMenu.save(date);
                this.$emit('input', date);
            },
            formatDate (date) {
                if (!date) {
                    return null;
                }

                return moment(date).format(this.options.dateFormat || 'DD/MM/YYYY');
            },
            parseDate (dateString) {
                if (!dateString) {
                    return null;
                }

                return moment(dateString, this.options.dateFormat || 'DD/MM/YYYY').format('YYYY-MM-DD');
            },
        },
        mounted() {
            this.pickerMonth = this.defaultMonth;
        },
    }
</script>