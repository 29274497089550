<template>
    <v-app id="gathering-admin">
        <login-refresh-dialog v-if="!getAuthToken"></login-refresh-dialog>

        <v-app-bar
            app
            dark
            color='#333'
            height='40'
            clipped-left
            dense
            flat
            fixed
            style='max-height: 55px'
        >
            <!--v-app-bar-nav-icon class='text-subtitle-1' @click="drawer = !drawer"></v-app-bar-nav-icon-->
            <!--v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon-->

            <template v-slot:img>
                <v-img
                    style="margin: 0 auto"
                    max-height="50"
                    max-width="200"
                    src="/img/icons/icon-400-400.png"
                ></v-img>
            </template>
            <v-toolbar-title class='legacy-snap-title pl-0'>SyncNova External Accounts Portal</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-toolbar-title class='title-internal-link mr-9'>
                <a class='d-block' value="left" href-disabled='https://snap.lism.catholic.edu.au'>
                    <v-icon color='#ddd' small>mdi-exit-to-app</v-icon>
                    Internal Accounts
                </a>
            </v-toolbar-title>

            <v-toolbar-title class='title-username mr-5'>
                <div class='caption'>{{activeUserName}}</div>
            </v-toolbar-title>

            <!--v-toolbar-title>
                <v-btn
                    icon
                >
                    <v-icon color='#999' small>mdi-help</v-icon>
                </v-btn>
            </v-toolbar-title-->

            <v-toolbar-title>
                <v-menu offset-y min-width='290'>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon color='#999' small>mdi-cog</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            link
                            to="/logout"
                        >
                            <v-list-item-icon class='mr-2'>
                                <v-icon>mdi-logout</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class='body-2'>Logout</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar-title>
        </v-app-bar>

        <v-navigation-drawer
            v-model="drawer"
            :clipped="true"
            app
        >
            <div>
                <v-list dense class='pb-16'>
                    <v-list-item>
                        <!--v-list-item-icon class='mr-5'>
                            <v-icon>mdi-view-dashboard-outline</v-icon>
                        </v-list-item-icon-->
                        <v-list-item-content>
                            <v-list-item-title v-if='accountTypes'>
                                <v-autocomplete
                                    clearable
                                    v-model="accountType"
                                    :items="accountTypes"
                                    return-object
                                    item-value='id'
                                    item-text='name'
                                    append-icon='mdi-menu'
                                    class='account-type-item'
                                    dense
                                    density='compact'
                                >
                                </v-autocomplete>
                            </v-list-item-title>
                            <v-list-item-title v-else>
                                loading...
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <!--v-subheader class='mt-8'>MANAGEMENT</v-subheader-->

                    <v-divider></v-divider>

                    <template v-if='accountType && accountType.id'>
                        <v-list-item class='mt-2' link to="/" v-if='accountType.access.viewAccount'>
                            <v-list-item-icon class='mr-5'>
                                <v-icon color='purple lighten-2'>mdi-account-multiple</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Accounts
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item class='mt-2' link to="/invites" v-if='accountType.access.inviteAccount'>
                            <v-list-item-icon class='mr-5'>
                                <v-icon color='purple lighten-2'>mdi-email-send</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Invite
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item class='mt-2' link to="/invites-pending" v-if='accountType.access.approveAccount'>
                            <v-list-item-icon class='mr-5'>
                                <v-icon color='purple lighten-2'>mdi-list-status</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Approvals
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item class='mt-2' to="/import" v-if='accountType.access.importAccount'>
                            <v-list-item-icon class='mr-5'>
                                <v-icon color='purple lighten-2'>mdi-account-arrow-left</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Import Accounts
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item class='mt-2' link to="/add" v-if='accountType.access.manualAccount'>
                            <v-list-item-icon class='mr-5'>
                                <v-icon color='purple lighten-2'>mdi-account-plus</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Add Account
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider class='mt-10'></v-divider>

                        <v-list-item class='mt-2' link to="/settings" v-if='isAdmin'>
                            <v-list-item-icon class='mr-5'>
                                <v-icon color='purple lighten-2'>mdi-cog</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Settings
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                    </template>
                </v-list>
            </div>
        </v-navigation-drawer>

        <v-main>
            <component v-if='accountType && accountType.id' :account-type='accountType' v-bind='$attrs' v-bind:is="currentPageComponent" :key='accountType.id'></component>
        </v-main>
        <v-footer
            dark
            color='#333'
            app
            style="z-index: 4"
        >
            <span class="white--text text-caption">&copy; {{ new Date().getFullYear() }}
                <span class='ml-3'>Catholic Schools Office, Lismore</span>
            </span>
            <v-spacer></v-spacer>
            <span class='ma-3 text-caption'><a href='/logout' class="white--text" style='text-decoration: none;'>Logout</a></span>
        </v-footer>

        <new-account-type
            v-if="accountType && !accountType.id"
            @created="fetchAccountTypes($event)"
            @close="fetchAccountTypes()"
        ></new-account-type>
    </v-app>
</template>

<script>
    // import axios from 'axios';
    import { mapGetters } from 'vuex';
    import siteVars from '../../site-vars';
    import axios from 'axios';

    import LoginRefreshDialog from './LoginRefreshDialog.vue';
    import Accounts from './Accounts.vue';
    import InviteList from './InviteList.vue';
    import InviteNew from './InviteNew.vue';
    import Import from './Import.vue';
    import Add from './Add.vue';
    import NewAccountType from './NewAccountType.vue';
    import Settings from './Settings.vue';

    export default {
        components: {
            LoginRefreshDialog,
            NewAccountType,
        },
        props: {
            nav: String,
            initialLog: String,
        },
        data: function() {
            return {
                drawer: null,
                error: null,
                loading: false,
                accountType: null,
                accountTypes: null,
                activeUserName: null,
                isAdmin: false,
            };
        },
        computed: {
            ...mapGetters({
                getAuthToken: 'authToken',
                previousAuth: 'previousAuth',
            }),
            currentPageComponent: function() {
                switch (this.$route.name) {
                    case 'accounts': return Accounts;
                    case 'invites': return InviteNew;
                    case 'invites-pending': return InviteList;
                    case 'import': return Import;
                    case 'settings': return Settings;
                    case 'add': return Add;
                    default:
                        if (this.$route.name.startsWith('help-')) {
                            return null;
                        }
                        if (this.$route.name.startsWith('settings-')) {
                            return null;
                        }
                }
                return Accounts;
            },
        },
        watch: {
            // accountType: function(newVal) {
            //     console.log(newVal);
            // },
        },
        methods: {
            fetchAccountTypes: function(defaultSelectedId) {
                const requestUrl = `${siteVars.apiUrl}/account-types`

                axios
                    .get(requestUrl, {
                        params: {
                        }
                    })
                    .then(response => {
                        if (response) {
                            this.activeUserName = response.data.activeUser.username;
                            this.isAdmin = response.data.activeUser.isAdmin;
                            this.accountTypes = response.data.data;
                            if (this.isAdmin) {
                                this.accountTypes.push({divider: true}, {name: 'New site...', id: 0});
                            }

                            if (defaultSelectedId) {
                                this.accountType = this.accountTypes.find(t => t.id == defaultSelectedId);
                            } else {
                                this.accountType = this.accountTypes[0];
                            }

                        }
                    });
            },
        },
        mounted() {
            this.fetchAccountTypes();
        },
    }
</script>

<style>
    @import "https://fonts.googleapis.com/css?family=Nova+Round";
    @import "https://fonts.googleapis.com/css?family=Material+Icons";
    @import "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900";
    @import "https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css";

    .legacy-snap-title {
        font-family: 'Nova Round', cursive !important;
        padding: 5px 15px;
        color: #8fa2c6 !important;
        font-size: 1.0rem !important;
    }

    .v-toolbar__content {
        padding-right: 0px !important;
    }

    .v-toolbar__title {
        color: #999;
        text-transform: none;
    }

    .v-navigation-drawer.v-navigation-drawer--open.theme--light {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08) !important;
    }

    .v-navigation-drawer__border {
        background-color: rgba(0, 0, 0, 0.0) !important;
    }
    .v-list .v-list-item--link::before {
        opacity: 0 !important;
        background-color: none !important;
    }

    a.v-list-item .v-list-item__title {
        color: #777 !important;
        font-size: 0.875rem !important;
        font-weight: normal !important;
    }

    a.v-list-item.v-list-item--active .v-list-item__title {
        color: #333 !important;
    }

    .title-internal-link a {
        font-size: 0.8em;
        color: #ddd !important;
        text-decoration: none;
    }

    .account-type-item {
        font-size: 0.9em;
    }
</style>